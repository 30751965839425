import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IntlMessages from "util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";

 const ConfirmDialog = ({open, handleClose, title, message, saving=false}) =>{
    return (
        <Dialog
            fullWidth
            open={open}
            // onClose={handleClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>

                <div className='position-relative'>

                    <Button onClick={() => handleClose(true)} color="primary" disabled={saving}>
                        <IntlMessages id='ok'/>
                    </Button>
                    {saving && <CircularProgress size={24} className='position-absolute text-primary'/>}
                </div>
                <Button onClick={() => handleClose(false)} color="warning" autoFocus disabled={saving}>
                    <IntlMessages id='cancel'/>
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDialog
