
// import moment from "moment";
import DateFnsAdapter from "@date-io/date-fns";
import {format} from "date-fns";
import vi from "date-fns/locale/vi";
const dateFns = new DateFnsAdapter();

export const keyboardDateTime12h = (datetime) => {
    if(datetime){
        return format(dateFns.date(datetime), 'Pp',{locale: vi});
    }
    return null
}

//
// export const keyboardDateTime12h = (datetime) => {
//     if(datetime){
//         return moment(datetime).format('DD/MM/yyyy hh:mm a')
//     }
//     return null
// }


export const keyboardDate= (datetime) => {
    if(datetime){
        return format(dateFns.date(datetime), 'P',{locale: vi});
    }
    return null
}
