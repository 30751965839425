import React from "react";
import {Grid, TextField, Typography} from '@material-ui/core'
import {errorDJRestToString} from "util/function/errorsProcess";


const CompanyForm = ({register, setValue, initState = {}, errors = {}}) => {


    return (
        <Grid container spacing={4}>

            <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" gutterBottom={true}>Tên công ty</Typography>
                <TextField inputRef={register} name="name" fullWidth error={!!errors.name}
                           helperText={errorDJRestToString(errors.name)} defaultValue={initState.name}/>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom={true}>Chào mừng</Typography>
                <TextField inputRef={register} name="welcome" fullWidth error={!!errors.welcome}
                           helperText={errorDJRestToString(errors.welcome)} defaultValue={initState.welcome}
                           multiline variant='outlined'/>
            </Grid>


        </Grid>
    )
}

export default CompanyForm
