import React, {useEffect} from "react";
import {Grid, Typography, TextField} from '@material-ui/core'
import CustomNumberField from "../../CustomField/CustomNumberField";
import {useSelector} from "react-redux";
import {errorDJRestToString} from "../../../util/function/errorsProcess";
import IntlMessages from "../../../util/IntlMessages";

const ApartmentTypeForm = ({register, setValue, initState={}, errors={}}) => {
    const {buildingId} = useSelector(({building}) => building)
    useEffect(()=>{
        register({name:'building_id'});
        setValue('building_id', buildingId);
    }, [buildingId])

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" gutterBottom={true}><IntlMessages id={'apartment_type'}/></Typography>
                <TextField inputRef={register} name="name" fullWidth error={!!errors.name} helperText={errorDJRestToString(errors.name)} defaultValue={initState.name}/>
            </Grid>
            <Grid item  xs={12} sm={6}>
                <Typography variant="subtitle1" gutterBottom={true}>Phòng ngủ</Typography>
                <CustomNumberField fieldName='bedrooms' register={register} setValue={setValue} decimalPlaces={0} required={true} defaultValue={initState['bedrooms']} errors={errors}/>
            </Grid>
            <Grid item  xs={12} sm={6}>
                <Typography variant="subtitle1" gutterBottom={true}>Phòng tắm</Typography>
                <CustomNumberField fieldName='bathrooms' register={register} setValue={setValue} decimalPlaces={0} required={true} defaultValue={initState['bathrooms']} errors={errors}/>
            </Grid>
            <Grid item  xs={12} sm={6}>
                <Typography variant="subtitle1" gutterBottom={true}>Bếp</Typography>
                <CustomNumberField fieldName='kitchens' register={register} setValue={setValue} decimalPlaces={0} required={true} defaultValue={initState['kitchens']} errors={errors}/>
            </Grid>
            <Grid item  xs={12} sm={6}>
                <Typography variant="subtitle1" gutterBottom={true}>Ban công</Typography>
                <CustomNumberField fieldName='balconies' register={register} setValue={setValue} decimalPlaces={0} required={true} defaultValue={initState['balconies']} errors={errors}/>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom={true} className='text-red'>{errors.non_field_errors}</Typography>
            </Grid>



        </Grid>
    )
}

export default ApartmentTypeForm
