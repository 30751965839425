import {PERMISSION_DICT} from "../../constants/statusDict/user";

const checkGroup = (groupsCheck, groupsCurrent) => {
    return groupsCurrent.some(r => groupsCheck.indexOf(r) >= 0);
}

export const checkViewBasicPermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.BASIC_VIEW]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkSuperAdminPermission = (groupsCurrent) => {
    const groupCheckList = ['is_super']
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkAdminPermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.ADMIN]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkBlockCreatePermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.ADMIN, PERMISSION_DICT.BLOCK_CREATE]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkApartmentTypeCreatePermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.ADMIN, PERMISSION_DICT.APARTMENT_TYPE_CREATE]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkApartmentCreatePermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.ADMIN, PERMISSION_DICT.APARTMENT_CREATE]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkApartmentViewPermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.ADMIN, PERMISSION_DICT.APARTMENT_CREATE, PERMISSION_DICT.APARTMENT_VIEW]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkDocumentCreatePermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.ADMIN, PERMISSION_DICT.DOCUMENT_CREATE]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkDocumentViewPermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.ADMIN, PERMISSION_DICT.DOCUMENT_CREATE , PERMISSION_DICT.DOCUMENT_VIEW]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkFeeCreatePermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.ADMIN, PERMISSION_DICT.FEE_CREATE]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkFeeViewPermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.ADMIN, PERMISSION_DICT.FEE_CREATE , PERMISSION_DICT.FEE_VIEW]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkReceiptViewPermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.ADMIN, PERMISSION_DICT.FEE_CREATE , PERMISSION_DICT.FEE_VIEW, PERMISSION_DICT.RECEIPT_CREATE]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkReceiptCreatePermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.ADMIN, PERMISSION_DICT.FEE_CREATE, PERMISSION_DICT.RECEIPT_CREATE]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkResidentCreatePermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.ADMIN, PERMISSION_DICT.RESIDENT_CREATE]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkResidentViewPermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.ADMIN, PERMISSION_DICT.RESIDENT_CREATE , PERMISSION_DICT.RESIDENT_VIEW]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkResidentViewLimitPermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.ADMIN, PERMISSION_DICT.RESIDENT_CREATE , PERMISSION_DICT.RESIDENT_VIEW, PERMISSION_DICT.RESIDENT_LIMIT_VIEW]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkTicketCreatePermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.ADMIN, PERMISSION_DICT.TICKET_CREATE]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkTicketViewPermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.ADMIN, PERMISSION_DICT.TICKET_CREATE , PERMISSION_DICT.TICKET_VIEW]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkAnnouncementCreatePermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.ADMIN, PERMISSION_DICT.ANNOUNCEMENT_CREATE]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkAnnouncementViewPermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.ADMIN, PERMISSION_DICT.ANNOUNCEMENT_CREATE , PERMISSION_DICT.ANNOUNCEMENT_VIEW]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkFacilityCreatePermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.ADMIN, PERMISSION_DICT.FACILITY_CREATE]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkFacilityViewPermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.ADMIN, PERMISSION_DICT.FACILITY_CREATE , PERMISSION_DICT.FACILITY_VIEW]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkMaintenanceViewPermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.ADMIN, PERMISSION_DICT.MAINTENANCE_CREATE , PERMISSION_DICT.MAINTENANCE_VIEW]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkMaintenanceCreatePermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.ADMIN, PERMISSION_DICT.MAINTENANCE_CREATE]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkParkingViewPermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.ADMIN, PERMISSION_DICT.PARKING_CREATE , PERMISSION_DICT.PARKING_VIEW]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkParkingCreatePermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.ADMIN, PERMISSION_DICT.PARKING_CREATE]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkDashBoardViewPermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.ADMIN, PERMISSION_DICT.DASHBOARD_VIEW]
    return checkGroup(groupCheckList, groupsCurrent)
}


export const checkThreadCreatePermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.ADMIN, PERMISSION_DICT.THREAD_CREATE]
    return checkGroup(groupCheckList, groupsCurrent)
}

export const checkThreadViewPermission = (groupsCurrent) => {
    const groupCheckList = [PERMISSION_DICT.ADMIN, PERMISSION_DICT.THREAD_CREATE , PERMISSION_DICT.THREAD_VIEW]
    return checkGroup(groupCheckList, groupsCurrent)
}


// -----------------------------------------------------------------------------------------------------


export const checkViewSectionOther = (groupsCurrent) => {
    return checkAnnouncementViewPermission(groupsCurrent)||checkFacilityViewPermission(groupsCurrent)||checkTicketViewPermission(groupsCurrent)||checkReceiptViewPermission((groupsCurrent))
}
