import {all, call, fork, put, select, takeEvery} from "redux-saga/effects";
import {
    FETCH_BUILDING,
    FETCH_BUILDINGS,
    FETCH_COMPANY,
    SET_BUILDING_ID_DEFAULT,
    SET_BUILDING_TYPE
} from "constants/ActionTypes";
import {showMessage} from "../actions/Common"
import axiosInstance from "../../util/axiosApi";
import {ROUTE_NAME, routeTo} from "../../util/services/routes";
import {
    getBuildingsSuccess,
    getBuildingSuccess,
    getCompanySuccess,
    setBuildingIdDefaultSuccess
} from "../actions/Building";
import {history} from "../../util/function/history";
import {BUILDING_TYPE_DICT} from "../../constants/statusDict/building";
import {switchLanguage} from "../actions";
import React from "react";

export const getBuildingState = ({building}) => building


const getBuildingRequest = async (action) =>
    await axiosInstance.get(routeTo(ROUTE_NAME.BUILDING.GET_BUILDING_DETAIL, {}, action.payload)).then(response => {
        return response
    }).catch(error => error)

function * getBuilding(action) {

    console.log(action)
    try {
        const building = yield call(getBuildingRequest, action);
        if (building.message) {
            yield put(showMessage(building.message));
        } else {
            yield put(getBuildingSuccess(building.data));

        }
    } catch (error) {
        yield put(showMessage(error));
    }
}


const getBuildingsRequest = async () =>
    await axiosInstance.get(routeTo(ROUTE_NAME.BUILDING.GET_BUILDINGS)).then(response => {
        return response
    }).catch(error => error)

function * getBuildings() {
    try {
        const buildings = yield call(getBuildingsRequest);
        if (buildings.message) {
            yield put(showMessage(buildings.message));
        } else {
            yield put(getBuildingsSuccess(buildings.data));

        }
    } catch (error) {
        yield put(showMessage(error));
    }
}


const getCompanyRequest = async () =>
    await axiosInstance.get(routeTo(ROUTE_NAME.COMPANY.GET_COMPANY)).then(response => {
        return response
    }).catch(error => error)

function * getCompany() {
    try {
        const res = yield call(getCompanyRequest);
        if (res.message) {
            yield put(showMessage(res.message));
        } else {
            yield put(getCompanySuccess(res.data));

        }
    } catch (error) {
        yield put(showMessage(error));
    }
}

function * setBuildingDefault(action) {
    let building = yield select(getBuildingState);
    yield put(setBuildingIdDefaultSuccess(action.payload));
    history.push(window.location.pathname.replace(building.buildingId, action.payload))
}

function * setLanguage(action) {
    if(action.payload === BUILDING_TYPE_DICT.TOWNHOUSE){
        yield put(switchLanguage('vinp'));
    }else {
        yield put(switchLanguage('vi'));
    }
}

export function* getListBuilding() {
    yield takeEvery(FETCH_BUILDINGS, getBuildings);
    yield takeEvery(SET_BUILDING_ID_DEFAULT, setBuildingDefault);
    yield takeEvery(SET_BUILDING_TYPE, setLanguage);
    yield takeEvery(FETCH_COMPANY, getCompany);
    yield takeEvery(FETCH_BUILDING, getBuilding);
}


export default function* rootSaga() {
    yield all([
        fork(getListBuilding)
    ]);
}

