import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {errorDJRestToString} from "../../util/function/errorsProcess";
import apiService from "../../util/services/generalApi";
import {ROUTE_NAME} from "../../util/services/routes";
import {showMessage} from "../../redux/actions";
import {useDispatch} from "react-redux";
import AutocompleteBaseField from "./AutocompleteBaseField";


const WardField = (props) => {
    const fieldName = 'ward_id'
    const dispatch = useDispatch()
    const {setValue, register, districtId, errors = {}, initInput = '', initValue} = props
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [userInput, setUserInput] = React.useState(initInput)

    const fetchData = async () => {
        setLoading(true)
        const response = await apiService.getList({
            filters: {limit: 100, offset: 0, district_id: districtId}, routeName: ROUTE_NAME.ADDRESS.WARDS
        });
        if (response.message) {
            dispatch(showMessage(response.message))
        } else {
            setData(response.data.results);
            setLoading(false);
        }
    }

    useEffect(() => {
        setValue(fieldName, initValue);
        if (districtId) {
            fetchData()
        }
    }, [districtId])

    const handleChange = (value) => {
        setValue(fieldName, value ? value.id : null);
    }

    const handleInputChange = (event, value, reason) => {
        setUserInput(value);
    }

    return (
        <AutocompleteBaseField key={data} fieldName={fieldName} onInputChange={handleInputChange} options={data ? data : []}
                               register={register} handleChange={handleChange}
                               error={errorDJRestToString(errors[fieldName])} initValue={initValue}
                               initInput={userInput} loading={loading}/>
    )
}


WardField.propTypes = {
    setValue: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    districtId: PropTypes.string.isRequired,
    errors: PropTypes.object,
    initInput: PropTypes.string,
    initValue: PropTypes.string,
};

export default WardField
