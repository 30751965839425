import {
    FETCH_BUILDING, FETCH_BUILDING_SUCCESS,
    FETCH_BUILDINGS,
    FETCH_BUILDINGS_SUCCESS, FETCH_COMPANY, FETCH_COMPANY_SUCCESS,
    SET_BUILDING_ID_DEFAULT, SET_BUILDING_ID_DEFAULT_SUCCESS, SET_BUILDING_TYPE,
} from 'constants/ActionTypes';


export const getBuildings = () => {
    return {
        type: FETCH_BUILDINGS,
    }
};

export const getBuildingsSuccess = (payload) => {
    return {
        type: FETCH_BUILDINGS_SUCCESS,
        payload: payload
    }
};

export const getBuilding = (payload) => {
    return {
        type: FETCH_BUILDING,
        payload: payload,
    }
};

export const getBuildingSuccess = (payload) => {
    return {
        type: FETCH_BUILDING_SUCCESS,
        payload: payload
    }
};

export const getCompany = () => {
    return {
        type: FETCH_COMPANY,
    }
};

export const getCompanySuccess = (payload) => {
    return {
        type: FETCH_COMPANY_SUCCESS,
        payload: payload
    }
};



export const setBuildingIdDefault = (payload) => {
    return {
        type: SET_BUILDING_ID_DEFAULT,
        payload: payload
    }
};
export const setBuildingType = (payload) => {
    return {
        type: SET_BUILDING_TYPE,
        payload: payload
    }
};


export const setBuildingIdDefaultSuccess = (payload) => {
    return {
        type: SET_BUILDING_ID_DEFAULT_SUCCESS,
        payload: payload
    }
};
