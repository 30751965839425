import React from 'react';
import ApartmentTypeForm from "../../../components/Forms/ApartmentTypeForm";
import {ROUTE_NAME} from "../../../util/services/routes";
import {checkApartmentTypeCreatePermission} from "../../../util/function/checkGroup";
import IntlMessages from "../../../util/IntlMessages";


export const apartmentTypeMenuSchema = {
    update: {
        type: 'update',
        name: 'Cập nhật',
        title: <IntlMessages id={'apartment_type_update'}/>,
        form: ()=>ApartmentTypeForm,
        checkGroup:checkApartmentTypeCreatePermission,
        conditionDisplay: (obj)=>true,
        urlParams: (obj)=> { return {id:obj.id}},
        routeName: ROUTE_NAME.APARTMENT_TYPE.UPDATE_APARTMENT_TYPE
    },
}
