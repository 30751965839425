import React from "react";

export const USER_STATUS_DICT = {
    ACTIVE: true,
    LOCKED: false
}

export const USER_DISPLAY_STATUS_DICT = {
    [USER_STATUS_DICT.ACTIVE]: 'Hoạt động',
    [USER_STATUS_DICT.LOCKED]: 'Đã khóa',
}



export const USER_STYLE_STATUS_DICT = {
    [USER_STATUS_DICT.ACTIVE]: 'bg-active text-white',
    [USER_STATUS_DICT.LOCKED]: 'bg-inactive text-white',
}


export const PERMISSION_DICT = {
    ADMIN: 'admin',

    BASIC_VIEW: 'basic_view',
    DASHBOARD_VIEW: 'dashboard_view',

    BLOCK_CREATE: 'block_create',
    APARTMENT_TYPE_CREATE: 'apartment_type_create',
    APARTMENT_VIEW: 'apartment_view',
    APARTMENT_CREATE: 'apartment_create',

    DOCUMENT_VIEW: 'document_view',
    DOCUMENT_CREATE: 'document_create',

    FEE_VIEW: 'fee_view',
    FEE_CREATE: 'fee_create',
    RECEIPT_CREATE: 'receipt_create',

    RESIDENT_VIEW: 'resident_view',
    RESIDENT_LIMIT_VIEW: 'resident_limit_view',
    RESIDENT_CREATE: 'resident_create',

    TICKET_VIEW: 'ticket_view',
    TICKET_CREATE: 'ticket_create',

    ANNOUNCEMENT_VIEW: 'announcement_view',
    ANNOUNCEMENT_CREATE: 'announcement_create',

    FACILITY_VIEW: 'facility_view',
    FACILITY_CREATE: 'facility_create',

    PARKING_VIEW: 'parking_view',
    PARKING_CREATE: 'parking_create',

    MAINTENANCE_VIEW: 'maintenance_view',
    MAINTENANCE_CREATE: 'maintenance_create',

    THREAD_VIEW: 'thread_view',
    THREAD_CREATE: 'thread_create',
}

export const PERMISSION_DISPLAY_DICT = {
    [PERMISSION_DICT.ADMIN]: "Quản trị hệ thống",

    [PERMISSION_DICT.BASIC_VIEW]: "Cơ bản",
    [PERMISSION_DICT.DASHBOARD_VIEW]: "Xem biểu đồ",

    [PERMISSION_DICT.BLOCK_CREATE]: "Tạo block",
    [PERMISSION_DICT.APARTMENT_TYPE_CREATE]: "Tạo cập nhật loại căn hộ",
    [PERMISSION_DICT.APARTMENT_CREATE]: "Tạo cập nhật căn hộ",
    [PERMISSION_DICT.APARTMENT_VIEW]: "Xem căn hộ",

    [PERMISSION_DICT.DOCUMENT_VIEW]: "Xem hồ sơ",
    [PERMISSION_DICT.DOCUMENT_CREATE]: "Tạo cập nhật hồ sơ",

    [PERMISSION_DICT.FEE_VIEW]: "Xem phí",
    [PERMISSION_DICT.FEE_CREATE]: "Tạo cập nhật phí",
    [PERMISSION_DICT.RECEIPT_CREATE]: "Chỉ gửi biên nhận",

    [PERMISSION_DICT.RESIDENT_VIEW]: "Xem cư dân",
    [PERMISSION_DICT.RESIDENT_LIMIT_VIEW]: "Xem cư dân hạn chế",
    [PERMISSION_DICT.RESIDENT_CREATE]: "Tạo cập nhật cư dân",

    [PERMISSION_DICT.TICKET_VIEW]: "Xem yêu cầu",
    [PERMISSION_DICT.TICKET_CREATE]: "Tạo cập nhật yêu cầu",

    [PERMISSION_DICT.ANNOUNCEMENT_VIEW]: "Xem thông báo",
    [PERMISSION_DICT.ANNOUNCEMENT_CREATE]: "Gửi thông báo",

    [PERMISSION_DICT.FACILITY_VIEW]: "Xem tiện ích",
    [PERMISSION_DICT.FACILITY_CREATE]: "Tạo cập nhật tiện ích",

    [PERMISSION_DICT.PARKING_VIEW]: "Xem bãi đỗ xe",
    [PERMISSION_DICT.PARKING_CREATE]: "Tạo cập nhật bãi đỗ xe",

    [PERMISSION_DICT.MAINTENANCE_VIEW]: "Xem bảo trì định kì",
    [PERMISSION_DICT.MAINTENANCE_CREATE]: "Tạo cập nhật bảo trì định kì",

    [PERMISSION_DICT.THREAD_VIEW]: "Xem khảo sát",
    [PERMISSION_DICT.THREAD_CREATE]: "Tạo khảo sát",
}

export const PERMISSION_GROUP_DISPLAY_DICT = {
    [PERMISSION_DICT.ADMIN]: "Quản trị hệ thống",
    [PERMISSION_DICT.DASHBOARD_VIEW]: "Tòa nhà",

    [PERMISSION_DICT.BLOCK_CREATE]: "Tòa nhà",
    [PERMISSION_DICT.APARTMENT_TYPE_CREATE]: "Tòa nhà",
    [PERMISSION_DICT.APARTMENT_VIEW]: "Tòa nhà",
    [PERMISSION_DICT.APARTMENT_CREATE]: "Tòa nhà",
    [PERMISSION_DICT.BASIC_VIEW]: "Tòa nhà",

    [PERMISSION_DICT.DOCUMENT_VIEW]: "Hồ sơ",
    [PERMISSION_DICT.DOCUMENT_CREATE]: "Hồ sơ",

    [PERMISSION_DICT.FEE_VIEW]: "Phí",
    [PERMISSION_DICT.FEE_CREATE]: "Phí",
    [PERMISSION_DICT.RECEIPT_CREATE]: "Phí",

    [PERMISSION_DICT.RESIDENT_VIEW]: "Cư dân",
    [PERMISSION_DICT.RESIDENT_CREATE]: "Cư dân",
    [PERMISSION_DICT.RESIDENT_LIMIT_VIEW]: "Cư dân",

    [PERMISSION_DICT.TICKET_VIEW]: "Yêu cầu",
    [PERMISSION_DICT.TICKET_CREATE]: "Yêu cầu",

    [PERMISSION_DICT.ANNOUNCEMENT_VIEW]: "Thông báo",
    [PERMISSION_DICT.ANNOUNCEMENT_CREATE]: "Thông báo",

    [PERMISSION_DICT.FACILITY_VIEW]: "Tiện ích",
    [PERMISSION_DICT.FACILITY_CREATE]: "Tiện ích",

    [PERMISSION_DICT.PARKING_VIEW]: "Bãi đỗ xe",
    [PERMISSION_DICT.PARKING_CREATE]: "Bãi đỗ xe",

    [PERMISSION_DICT.MAINTENANCE_VIEW]: "Bảo trì định kì",
    [PERMISSION_DICT.MAINTENANCE_CREATE]: "Bảo trì định kì",

    [PERMISSION_DICT.THREAD_VIEW]: "Khảo sát",
    [PERMISSION_DICT.THREAD_CREATE]: "Khảo sát",
}
