import React from 'react';
import {Badge, List} from '@material-ui/core';
import {NavLink} from 'react-router-dom';

import IntlMessages from "../../util/IntlMessages";

const NavMenuItem = ({name, icon, link, count}) => {
  return (
    <List component="div" className='nav-menu-item' disablePadding>
      <NavLink className="prepend-icon nav-menu-link" to={link}>
        {!!icon && (
            <Badge badgeContent={count||0} color="error" className={`animated infinite`} max={99} anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }} variant="dot"
                   className={`animated infinite ${!!count&&'wobble'}`}
            >
                <i className={'font-size-18 zmdi zmdi-hc-fw  zmdi-' + icon}/>
            </Badge>
        )}
        <span className="nav-text"><IntlMessages id={name}/></span>
      </NavLink>
    </List>
  )
};

export default NavMenuItem;
