//Auth const
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const INIT_URL = 'init_url';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';
export const GET_PROFILE_USER = 'LOAD_PROFILE_USER';
export const GET_PROFILE_USER_SUCCESS = 'LOAD_PROFILE_USER_SUCCESS';

// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';

export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style';
export const VERTICAL_NAVIGATION = 'vertical_navigation';

export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';
export const ON_HIDE_LOADER='ON_HIDE_LOADER'
export const ON_SHOW_LOADER='ON_SHOW_LOADER'
//Contact Module const

export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';


// building
export const FETCH_BUILDINGS = 'FETCH_BUILDINGS';
export const FETCH_BUILDINGS_SUCCESS = 'FETCH_BUILDINGS_SUCCESS';
export const SET_BUILDING_ID_DEFAULT = 'SET_BUILDING_ID_DEFAULT';
export const SET_BUILDING_TYPE = 'SET_BUILDING_TYPE';
export const SET_BUILDING_ID_DEFAULT_SUCCESS = 'SET_BUILDING_ID_DEFAULT_SUCCESS';

export const FETCH_BUILDING = 'FETCH_BUILDING';
export const FETCH_BUILDING_SUCCESS = 'FETCH_BUILDING_SUCCESS';

export const FETCH_COMPANY = 'FETCH_COMPANY';
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';

// Block
export const FETCH_BLOCKS = 'FETCH_BLOCKS';
export const FETCH_BLOCKS_SUCCESS = 'FETCH_BLOCKS_SUCCESS';


// Notification
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const TOGGLE_DROPDOWN_NOTIFICATION = 'TOGGLE_DROPDOWN_NOTIFICATION'
