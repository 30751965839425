import {objectToQueryString} from "../function/queryString";

const {compile} = require("path-to-regexp");

// local
// const API_URL = 'http://127.0.0.1:8129';
// const API_URL = 'https://gateway-manager.alpha.houze.io';
const API_URL = process.env.REACT_APP_ENV === 'alpha' ? 'https://gateway-manager.alpha.houze.io' : process.env.REACT_APP_ENV === 'prod' ? 'https://manager.houze.io' : 
process.env.REACT_APP_ENV === 'beta' ? 'https://gateway-manager.beta.houze.io' : 'https://gateway-manager.alpha.houze.io';


export const ROUTE_NAME = {
    AUTH: {
        REFRESH_TOKEN: 'auth.refresh.token',
        AUTH_TOKEN: 'auth.login.token',
        PROFILE: 'auth.get.profile',
        CHANGE_PASSWORD: 'CHANGE_PASSWORD',
        UPDATE_PROFILE_IMAGE: 'UPDATE_PROFILE_IMAGE',
    },
    USER: {
        GET_USERS: 'GET_USERS',
        CREATE_USER: 'CREATE_USER',
        UPDATE_USER: 'UPDATE_USER',
    },
    PROPERTY: {
        AUTOCOMPLETE_BUILDINGS: 'AUTOCOMPLETE_BUILDINGS'
    },
    FEE: {
        GET_FEES: 'GET_FEES',
        CREATE_FEE: 'CREATE_FEE',
        GET_FEE: 'GET_FEE',
        UPDATE_FEE: 'UPDATE_FEE',

        DELETE_FEE: 'DELETE_FEE',
        EXPORT_FEE: 'EXPORT_FEE',
        EXPORT_APARTMENT_FEE: 'EXPORT_APARTMENT_FEE',

        CREATE_FEE_SENT: 'CREATE_FEE_SENT',
    },
    FEE_IMPORT: {
        GET_FEE_IMPORTS: 'GET_FEE_IMPORTS',
        CREATE_FEE_IMPORT: 'CREATE_FEE_IMPORT',
        GET_FEE_IMPORT: 'GET_FEE_IMPORT',
        UPDATE_FEE_IMPORT: 'UPDATE_FEE_IMPORT',
    },
    RECEIPT_IMPORT: {
        GET_RECEIPT_IMPORTS: 'GET_RECEIPT_IMPORTS',
        CREATE_RECEIPT_IMPORT: 'CREATE_RECEIPT_IMPORT',
    },

    RECEIPT: {
        GET_RECEIPTS: 'GET_RECEIPTS',
        GET_RECEIPT: 'GET_RECEIPT',
        CREATE_RECEIPT: 'CREATE_RECEIPT',
        EXPORT_RECEIPT: 'EXPORT_RECEIPT',
    },
    APARTMENT_ACC: {
        GET_APARTMENT_ACC: 'GET_APARTMENT_ACC',
        GET_APARTMENT_ACC_LIST: 'GET_APARTMENT_ACC_LIST',
        GET_APARTMENT_ACC_HISTORY: 'GET_APARTMENT_ACC_HISTORY',
    },
    PAYMENT: {
        GET_PAYMENTS: 'GET_PAYMENTS',
    },
    DASHBOARD: {
        GET_DASHBOARD_FEE_BY_TYPE: 'GET_DASHBOARD_FEE_BY_TYPE',
        GET_DASHBOARD_FEE_BY_MONTH: 'GET_DASHBOARD_FEE_BY_MONTH',

        GET_DASHBOARD_RESIDENT: 'GET_DASHBOARD_RESIDENT',
        GET_DASHBOARD_TICKET_GENERAL: 'GET_DASHBOARD_TICKET_GENERAL',
        GET_DASHBOARD_TICKET_EXECUTION: 'GET_DASHBOARD_TICKET_EXECUTION',
        GET_DASHBOARD_TICKET_MONTH_EXECUTION: 'GET_DASHBOARD_TICKET_MONTH_EXECUTION',

        GET_DASHBOARD_TICKET_TYPE: 'GET_DASHBOARD_TICKET_TYPE',
        GET_DASHBOARD_TICKET_PERFORMANCE: 'GET_DASHBOARD_TICKET_PERFORMANCE',
    },
    BUILDING: {
        GET_BUILDINGS: 'GET_BUILDINGS',
        GET_BUILDING_DETAIL: 'GET_BUILDING_DETAIL',
        CREATE_BUILDING: 'CREATE_BUILDING',
        UPDATE_BUILDING: 'UPDATE_BUILDING'
    },
    APARTMENT_TYPE: {
        GET_APARTMENT_TYPES: 'GET_APARTMENT_TYPES',
        CREATE_APARTMENT_TYPE: 'CREATE_APARTMENT_TYPE',
        UPDATE_APARTMENT_TYPE: 'UPDATE_APARTMENT_TYPE',
    },
    BLOCK: {
        GET_BLOCKS: 'GET_BLOCKS',
        CREATE_BLOCK: 'CREATE_BLOCK',
        UPDATE_BLOCK: 'UPDATE_BLOCK',
    },
    FLOOR: {
        GET_FLOORS: 'GET_FLOORS',
    },
    COMPANY: {
        GET_COMPANY: 'GET_COMPANY',
        CREATE_COMPANY: 'CREATE_COMPANY',
        UPDATE_COMPANY: 'UPDATE_COMPANY',
    },
    ADDRESS: {
        PROVINCES: 'PROVINCES',
        DISTRICTS: 'DISTRICTS',
        WARDS: 'WARDS'
    },
    APARTMENT: {
        CREATE_APARTMENT: 'CREATE_APARTMENT',
        GET_APARTMENTS: 'GET_APARTMENTS',
        GET_APARTMENT: 'GET_APARTMENT',
        UPDATE_APARTMENT: 'UPDATE_APARTMENT',
    },
    RESIDENT: {
        CREATE_RESIDENT: 'CREATE_RESIDENT',
        GET_RESIDENTS: 'GET_RESIDENTS',
        GET_RESIDENT: 'GET_RESIDENT',
        UPDATE_RESIDENT: 'UPDATE_RESIDENT',
    },
    INOUT: {
        CREATE_INOUT: 'CREATE_INOUT',
        GET_INOUT_LIST: 'GET_INOUT_LIST',
        UPDATE_INOUT: 'UPDATE_INOUT',
    },
    TICKET: {
        CREATE_TICKET: 'CREATE_TICKET',
        GET_TICKETS: 'GET_TICKETS',
        GET_TICKET: 'GET_TICKET',
        UPDATE_TICKET: 'UPDATE_TICKET',

        CREATE_TICKET_COMMENT: 'CREATE_TICKET_COMMENT',
        GET_TICKET_COMMENTS: 'GET_TICKET_COMMENTS',

        GET_TICKET_COMMENTS_NOTIFY: 'GET_TICKET_COMMENTS_NOTIFY',

        CREATE_TICKET_ASSIGN: 'CREATE_TICKET_ASSIGN',
    },
    FACILITY: {
        CREATE_FACILITY: 'CREATE_FACILITY',
        UPDATE_FACILITY: 'UPDATE_FACILITY',
        GET_FACILITY: 'GET_FACILITY',
        GET_FACILITIES: 'GET_FACILITIES',

        GET_FACILITY_IMAGES: 'GET_FACILITY_IMAGE',
        CREATE_FACILITY_IMAGE: 'CREATE_FACILITY_IMAGE',
        DELETE_FACILITY_IMAGE: 'DELETE_FACILITY_IMAGE',

        GET_FACILITY_SLOTS: 'GET_FACILITY_SLOTS',
        CREATE_FACILITY_SLOT: 'CREATE_FACILITY_SLOT',
        UPDATE_FACILITY_SLOT: 'UPDATE_FACILITY_SLOT',

        GET_FACILITY_DAY_OFFS: 'GET_FACILITY_DAY_OFFS',
        CREATE_FACILITY_DAY_OFF: 'CREATE_FACILITY_DAY_OFF',
        UPDATE_FACILITY_DAY_OFF: 'UPDATE_FACILITY_DAY_OFF',
        DELETE_FACILITY_DAY_OFF: 'DELETE_FACILITY_DAY_OFF',

        GET_FACILITY_TIMES: 'GET_FACILITY_TIMES',
        CREATE_FACILITY_TIME: 'CREATE_FACILITY_TIME',
        UPDATE_FACILITY_TIME: 'UPDATE_FACILITY_TIME',
        DELETE_FACILITY_TIME: 'DELETE_FACILITY_TIME',

        GET_FACILITY_BOOKINGS: 'GET_FACILITY_BOOKINGS',
        UPDATE_FACILITY_BOOKING: 'UPDATE_FACILITY_BOOKING',

        GET_FACILITY_NOTIFY: 'GET_FACILITY_NOTIFY',
    },
    ANNOUNCEMENT: {
        CREATE_ANNOUNCEMENT: 'CREATE_ANNOUNCEMENT',
        CREATE_ANNOUNCEMENT_USER: 'CREATE_ANNOUNCEMENT_USER',
        CREATE_ANNOUNCEMENT_FEE_REMINDER: 'CREATE_ANNOUNCEMENT_FEE_REMINDER',
        GET_ANNOUNCEMENTS: 'GET_ANNOUNCEMENTS',
    },
    DOCUMENT: {
        GET_DOCUMENTS: 'GET_DOCUMENTS',
        CREATE_DOCUMENT: 'CREATE_DOCUMENT',
        DELETE_DOCUMENT: 'DELETE_DOCUMENT',
    },
    MAINTENANCE: {
        GET_MAINTENANCES: 'GET_MAINTENANCES',
        CREATE_MAINTENANCE: 'CREATE_MAINTENANCE',
        UPDATE_MAINTENANCE: 'UPDATE_MAINTENANCE',

        GET_MAINTENANCE_TASKS: 'GET_MAINTENANCE_TASKS',
    },
    PARKING: {
        GET_PARKING_LIST: 'GET_PARKING_LIST',
        CREATE_PARKING: 'CREATE_PARKING',
        UPDATE_PARKING: 'UPDATE_PARKING',

        GET_PARKING_VEHICLES: 'GET_PARKING_VEHICLES',
        CREATE_PARKING_VEHICLE: 'GET_PARKING_VEHICLE',
        UPDATE_PARKING_VEHICLE: 'UPDATE_PARKING_VEHICLE',
        GET_PARKING_VEHICLE_DETAIL: 'GET_PARKING_VEHICLE_DETAIL',

        GET_VEHICLE_IMAGES: 'GET_VEHICLE_IMAGES',
        CREATE_VEHICLE_IMAGE: 'CREATE_VEHICLE_IMAGE',
        DELETE_VEHICLE_IMAGE: 'DELETE_VEHICLE_IMAGE',

        GET_PARKING_VEHICLE_BOOKINGS: 'GET_PARKING_VEHICLE_BOOKINGS',
        UPDATE_PARKING_VEHICLE_BOOKING: 'UPDATE_PARKING_VEHICLE_BOOKING',

        GET_PARKING_NOTIFY: 'GET_PARKING_NOTIFY',

        EXPORT_PARKING_VEHICLE: 'EXPORT_PARKING_VEHICLE',
    },

    STORAGE_SERVICE: {
        UPLOAD_IMAGE: 'UPLOAD_IMAGE',
    },

    HOUZE_FORUM: {
        HOUZE_FORUM_THREADS: 'HOUZE_FORUM_THREADS',
        HOUZE_FORUM_THREAD_UPDATE: 'HOUZE_FORUM_THREAD_UPDATE',

        HOUZE_FORUM_CHOICES: 'HOUZE_FORUM_CHOICES',
        HOUZE_FORUM_CHOICES_UPDATE: 'HOUZE_FORUM_CHOICES_UPDATE',

        HOUZE_FORUM_USER_CHOICES: 'HOUZE_FORUM_USER_CHOICES',

        HOUZE_FORUM_COMMENTS: 'HOUZE_FORUM_COMMENTS',
        HOUZE_FORUM_COMMENT_UPDATE: 'HOUZE_FORUM_COMMENT_UPDATE',

        HOUZE_FORUM_IMAGES: 'HOUZE_FORUM_IMAGES',
        HOUZE_FORUM_IMAGES_DELETE: 'HOUZE_FORUM_IMAGES_DELETE',

        HOUZE_FORUM_USER_BLACKLISTS: 'HOUZE_FORUM_USER_BLACKLISTS',
        HOUZE_FORUM_USER_BLACKLIST_UPDATE: 'HOUZE_FORUM_USER_BLACKLIST_UPDATE',
    }
}


const routes_config = {
    [ROUTE_NAME.AUTH.AUTH_TOKEN]: '/auth-service/auth/manager/login',
    [ROUTE_NAME.AUTH.PROFILE]: '/manager-service/manager/profile/',
    [ROUTE_NAME.AUTH.CHANGE_PASSWORD]: '/manager-service/manager/change-password/',
    [ROUTE_NAME.AUTH.UPDATE_PROFILE_IMAGE]: '/manager-service/manager/update-image/',

    [ROUTE_NAME.AUTH.REFRESH_TOKEN]: '/auth-service/auth/refresh-token',

    [ROUTE_NAME.COMPANY.GET_COMPANY]: '/building-service/company/',
    [ROUTE_NAME.COMPANY.UPDATE_COMPANY]: '/building-service/company/',

    [ROUTE_NAME.ADDRESS.PROVINCES]: '/building-service/address/province/',
    [ROUTE_NAME.ADDRESS.DISTRICTS]: '/building-service/address/district/',
    [ROUTE_NAME.ADDRESS.WARDS]: '/building-service/address/ward/',

    [ROUTE_NAME.BUILDING.GET_BUILDINGS]: '/building-service/building/list/',
    [ROUTE_NAME.BUILDING.CREATE_BUILDING]: '/building-service/building/create/',
    [ROUTE_NAME.BUILDING.GET_BUILDING_DETAIL]: '/building-service/building/:id/',
    [ROUTE_NAME.BUILDING.UPDATE_BUILDING]: '/building-service/building/:id/',

    [ROUTE_NAME.APARTMENT_TYPE.CREATE_APARTMENT_TYPE]: '/building-service/apartment-type/create/',
    [ROUTE_NAME.APARTMENT_TYPE.GET_APARTMENT_TYPES]: '/building-service/apartment-type/list/',
    [ROUTE_NAME.APARTMENT_TYPE.UPDATE_APARTMENT_TYPE]: '/building-service/apartment-type/:id/',

    [ROUTE_NAME.BLOCK.GET_BLOCKS]: '/building-service/block/list/',
    [ROUTE_NAME.BLOCK.CREATE_BLOCK]: '/building-service/block/create/',
    [ROUTE_NAME.BLOCK.UPDATE_BLOCK]: '/building-service/block/:id/',

    [ROUTE_NAME.FLOOR.GET_FLOORS]: '/building-service/floor/',

    [ROUTE_NAME.APARTMENT.GET_APARTMENTS]: '/building-service/apartment/',
    [ROUTE_NAME.APARTMENT.CREATE_APARTMENT]: '/building-service/apartment/',
    [ROUTE_NAME.APARTMENT.GET_APARTMENT]: '/building-service/apartment/:id/',
    [ROUTE_NAME.APARTMENT.UPDATE_APARTMENT]: '/building-service/apartment/:id/',

    [ROUTE_NAME.RESIDENT.GET_RESIDENTS]: '/building-service/resident/',
    [ROUTE_NAME.RESIDENT.CREATE_RESIDENT]: '/building-service/resident/',
    [ROUTE_NAME.RESIDENT.GET_RESIDENT]: '/building-service/resident/:id/',
    [ROUTE_NAME.RESIDENT.UPDATE_RESIDENT]: '/building-service/resident/:id/',

    [ROUTE_NAME.TICKET.CREATE_TICKET]: '/ticket-service/ticket/create/',
    [ROUTE_NAME.TICKET.GET_TICKETS]: '/ticket-service/ticket/',
    [ROUTE_NAME.TICKET.GET_TICKET]: '/ticket-service/ticket/:id/',
    [ROUTE_NAME.TICKET.UPDATE_TICKET]: '/ticket-service/ticket/:id/',

    [ROUTE_NAME.TICKET.CREATE_TICKET_COMMENT]: '/ticket-service/ticket/:id/comment/',
    [ROUTE_NAME.TICKET.GET_TICKET_COMMENTS]: '/ticket-service/ticket/:id/comment/',
    [ROUTE_NAME.TICKET.GET_TICKET_COMMENTS_NOTIFY]: '/ticket-service/ticket/notification/',

    [ROUTE_NAME.TICKET.CREATE_TICKET_ASSIGN]: '/ticket-service/ticket-assign-config/:buildingId/',


    [ROUTE_NAME.USER.GET_USERS]: '/manager-service/manager/',
    [ROUTE_NAME.USER.CREATE_USER]: '/manager-service/manager/',
    [ROUTE_NAME.USER.UPDATE_USER]: '/manager-service/manager/:id/',

    [ROUTE_NAME.INOUT.GET_INOUT_LIST]: '/building-service/inout/',
    [ROUTE_NAME.INOUT.CREATE_INOUT]: '/building-service/inout/',
    [ROUTE_NAME.INOUT.UPDATE_INOUT]: '/building-service/inout/:id/',

    [ROUTE_NAME.PROPERTY.AUTOCOMPLETE_BUILDINGS]: '/property/building/',

    [ROUTE_NAME.FEE.GET_FEES]: '/fee-service/fee/',
    [ROUTE_NAME.FEE.CREATE_FEE]: '/fee-service/fee/',
    [ROUTE_NAME.FEE.GET_FEE]: '/fee-service/fee/:id/',
    [ROUTE_NAME.FEE.UPDATE_FEE]: '/fee-service/fee/:id/',

    [ROUTE_NAME.FEE.DELETE_FEE]: '/fee-service/fee/delete/:id/',

    [ROUTE_NAME.FEE.EXPORT_FEE]: '/fee-service/export/export-fee/',
    [ROUTE_NAME.RECEIPT.EXPORT_RECEIPT]: '/fee-service/export/export-receipt/',
    [ROUTE_NAME.FEE.CREATE_FEE_SENT]: '/fee-service/fee-sent/',

    [ROUTE_NAME.FEE.EXPORT_APARTMENT_FEE]: '/fee-service/export/export-fee-pdf/:id/',

    [ROUTE_NAME.FEE_IMPORT.GET_FEE_IMPORTS]: '/fee-service/fee-import/',
    [ROUTE_NAME.FEE_IMPORT.CREATE_FEE_IMPORT]: '/fee-service/fee-import/',
    [ROUTE_NAME.FEE_IMPORT.GET_FEE_IMPORT]: '/fee-service/fee-import/:id/',
    [ROUTE_NAME.FEE_IMPORT.UPDATE_FEE_IMPORT]: '/fee-service/fee-import/:id/',

    [ROUTE_NAME.RECEIPT.GET_RECEIPTS]: '/fee-service/receipt/',
    [ROUTE_NAME.RECEIPT.CREATE_RECEIPT]: '/fee-service/receipt/',
    [ROUTE_NAME.RECEIPT.GET_RECEIPT]: '/fee-service/receipt/:id/',

    [ROUTE_NAME.RECEIPT_IMPORT.GET_RECEIPT_IMPORTS]: '/fee-service/receipt-import/',
    [ROUTE_NAME.RECEIPT_IMPORT.CREATE_RECEIPT_IMPORT]: '/fee-service/receipt-import/',

    [ROUTE_NAME.FACILITY.GET_FACILITIES]: '/facility-service/facility/',
    [ROUTE_NAME.FACILITY.CREATE_FACILITY]: '/facility-service/facility/',
    [ROUTE_NAME.FACILITY.GET_FACILITY]: '/facility-service/facility/:id/',
    [ROUTE_NAME.FACILITY.UPDATE_FACILITY]: '/facility-service/facility/:id/',

    [ROUTE_NAME.FACILITY.GET_FACILITY_IMAGES]: '/facility-service/:id/image/',
    [ROUTE_NAME.FACILITY.CREATE_FACILITY_IMAGE]: '/facility-service/:id/image/',
    [ROUTE_NAME.FACILITY.DELETE_FACILITY_IMAGE]: '/facility-service/image/:id/',

    [ROUTE_NAME.FACILITY.GET_FACILITY_SLOTS]: '/facility-service/:id/slot/',
    [ROUTE_NAME.FACILITY.CREATE_FACILITY_SLOT]: '/facility-service/:id/slot/',
    [ROUTE_NAME.FACILITY.UPDATE_FACILITY_SLOT]: '/facility-service/slot/:id/',

    [ROUTE_NAME.FACILITY.GET_FACILITY_DAY_OFFS]: '/facility-service/:id/day-off/',
    [ROUTE_NAME.FACILITY.CREATE_FACILITY_DAY_OFF]: '/facility-service/:id/day-off/',
    [ROUTE_NAME.FACILITY.UPDATE_FACILITY_DAY_OFF]: '/facility-service/day-off/:id/',
    [ROUTE_NAME.FACILITY.DELETE_FACILITY_DAY_OFF]: '/facility-service/day-off/:id/',

    [ROUTE_NAME.FACILITY.GET_FACILITY_TIMES]: '/facility-service/:id/time/',
    [ROUTE_NAME.FACILITY.CREATE_FACILITY_TIME]: '/facility-service/:id/time/',
    [ROUTE_NAME.FACILITY.UPDATE_FACILITY_TIME]: '/facility-service/time/:id/',
    [ROUTE_NAME.FACILITY.DELETE_FACILITY_TIME]: '/facility-service/time/:id/',

    [ROUTE_NAME.FACILITY.GET_FACILITY_BOOKINGS]: '/facility-service/facility-booking/',
    [ROUTE_NAME.FACILITY.UPDATE_FACILITY_BOOKING]: '/facility-service/facility-booking/:id/',

    [ROUTE_NAME.FACILITY.GET_FACILITY_NOTIFY]: '/facility-service/notification/',

    [ROUTE_NAME.ANNOUNCEMENT.GET_ANNOUNCEMENTS]: '/announcement-service/announcement/',
    [ROUTE_NAME.ANNOUNCEMENT.CREATE_ANNOUNCEMENT]: '/announcement-service/create-announcement/',
    [ROUTE_NAME.ANNOUNCEMENT.CREATE_ANNOUNCEMENT_USER]: '/announcement-service/create-user-announcement/',
    [ROUTE_NAME.ANNOUNCEMENT.CREATE_ANNOUNCEMENT_FEE_REMINDER]: '/announcement-service/create-fee-reminder-announcement/',

    [ROUTE_NAME.DOCUMENT.GET_DOCUMENTS]: '/document-service/document/',
    [ROUTE_NAME.DOCUMENT.CREATE_DOCUMENT]: '/document-service/document/',
    [ROUTE_NAME.DOCUMENT.DELETE_DOCUMENT]: '/document-service/document/:id/',

    [ROUTE_NAME.PAYMENT.GET_PAYMENTS]: '/payment/',

    [ROUTE_NAME.DASHBOARD.GET_DASHBOARD_FEE_BY_TYPE]: '/fee-service/dashboard/fee-group-by-type/',
    [ROUTE_NAME.DASHBOARD.GET_DASHBOARD_FEE_BY_MONTH]: '/fee-service/dashboard/fee-group-by-month/',
    [ROUTE_NAME.DASHBOARD.GET_DASHBOARD_RESIDENT]: '/building-service/dashboard/resident/',
    [ROUTE_NAME.DASHBOARD.GET_DASHBOARD_TICKET_GENERAL]: '/ticket-service/dashboard/general/',
    [ROUTE_NAME.DASHBOARD.GET_DASHBOARD_TICKET_EXECUTION]: '/ticket-service/dashboard/execution/',
    [ROUTE_NAME.DASHBOARD.GET_DASHBOARD_TICKET_MONTH_EXECUTION]: '/ticket-service/dashboard/month-execution/',
    [ROUTE_NAME.DASHBOARD.GET_DASHBOARD_TICKET_TYPE]: '/ticket-service/dashboard/by-type/',
    [ROUTE_NAME.DASHBOARD.GET_DASHBOARD_TICKET_PERFORMANCE]: '/ticket-service/dashboard/by-performance/',

    [ROUTE_NAME.MAINTENANCE.GET_MAINTENANCES]: '/maintenance-service/maintenance/',
    [ROUTE_NAME.MAINTENANCE.CREATE_MAINTENANCE]: '/maintenance-service/maintenance/',
    [ROUTE_NAME.MAINTENANCE.UPDATE_MAINTENANCE]: '/maintenance-service/maintenance/:id/',
    [ROUTE_NAME.MAINTENANCE.GET_MAINTENANCE_TASKS]: '/maintenance-service/task/',

    [ROUTE_NAME.PARKING.GET_PARKING_LIST]: '/parking-service/parking/',
    [ROUTE_NAME.PARKING.CREATE_PARKING]: '/parking-service/parking/',
    [ROUTE_NAME.PARKING.UPDATE_PARKING]: '/parking-service/parking/:id/',

    [ROUTE_NAME.PARKING.GET_PARKING_VEHICLES]: '/parking-service/parking-vehicle/',
    [ROUTE_NAME.PARKING.CREATE_PARKING_VEHICLE]: '/parking-service/parking-vehicle/',
    [ROUTE_NAME.PARKING.GET_PARKING_VEHICLE_DETAIL]: '/parking-service/parking-vehicle/:id/',
    [ROUTE_NAME.PARKING.UPDATE_PARKING_VEHICLE]: '/parking-service/parking-vehicle/:id/',

    [ROUTE_NAME.PARKING.EXPORT_PARKING_VEHICLE]: '/parking-service/export/parking-vehicle/',

    [ROUTE_NAME.PARKING.CREATE_VEHICLE_IMAGE]: '/parking-service/:id/vehicle-image/',
    [ROUTE_NAME.PARKING.GET_VEHICLE_IMAGES]: '/parking-service/:id/vehicle-image/',
    [ROUTE_NAME.PARKING.DELETE_VEHICLE_IMAGE]: '/parking-service/vehicle-image/:id/',

    [ROUTE_NAME.PARKING.GET_PARKING_VEHICLE_BOOKINGS]: '/parking-service/parking-vehicle-booking/',
    [ROUTE_NAME.PARKING.UPDATE_PARKING_VEHICLE_BOOKING]: '/parking-service/parking-vehicle-booking/:id/',

    [ROUTE_NAME.PARKING.GET_PARKING_NOTIFY]: '/parking-service/notification/',

    [ROUTE_NAME.STORAGE_SERVICE.UPLOAD_IMAGE]: '/storage-service/upload-image/',

    [ROUTE_NAME.HOUZE_FORUM.HOUZE_FORUM_THREADS]: '/houze-forum/threads/',
    [ROUTE_NAME.HOUZE_FORUM.HOUZE_FORUM_THREAD_UPDATE]: '/houze-forum/threads/:id/',
    [ROUTE_NAME.HOUZE_FORUM.HOUZE_FORUM_IMAGES]: '/houze-forum/threads/:id/images/',

    [ROUTE_NAME.HOUZE_FORUM.HOUZE_FORUM_CHOICES]: '/houze-forum/polls/:id/choices/',
    [ROUTE_NAME.HOUZE_FORUM.HOUZE_FORUM_COMMENTS]: '/houze-forum/comments/',
    [ROUTE_NAME.HOUZE_FORUM.HOUZE_FORUM_COMMENT_UPDATE]: '/houze-forum/comments/:id/',

    [ROUTE_NAME.HOUZE_FORUM.HOUZE_FORUM_CHOICES_UPDATE]: '/houze-forum/choices/:id/',
    [ROUTE_NAME.HOUZE_FORUM.HOUZE_FORUM_USER_CHOICES]: '/houze-forum/polls/:id/user-choices/',

    [ROUTE_NAME.HOUZE_FORUM.HOUZE_FORUM_USER_BLACKLISTS]: '/houze-forum/user-blacklists/',
    [ROUTE_NAME.HOUZE_FORUM.HOUZE_FORUM_USER_BLACKLIST_UPDATE]: '/houze-forum/user-blacklists/:id/',

    [ROUTE_NAME.APARTMENT_ACC.GET_APARTMENT_ACC_LIST]: '/fee-service/apartment-acc/',
    [ROUTE_NAME.APARTMENT_ACC.GET_APARTMENT_ACC]: '/fee-service/apartment-acc/:id/',
    [ROUTE_NAME.APARTMENT_ACC.GET_APARTMENT_ACC_HISTORY]: '/fee-service/apartment-acc/history/:id/',


}

export const routeTo = (name, params = {}, url_params) => API_URL + compile(routes_config[name])(url_params) + objectToQueryString(params);


export const routeToEndpoint = (endpoint, params = {}) => endpoint + objectToQueryString(params);
