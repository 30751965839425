import Button from "@material-ui/core/Button";
import React from "react";
import BuildingForm from "components/Forms/BuildingForm";
import FormDialog from "components/Dialog/FormDialog";
import {showMessage} from "../../redux/actions";
import apiService from "../../util/services/generalApi";
import {ROUTE_NAME} from "../../util/services/routes";
import {useDispatch, useSelector} from "react-redux";
import {getBuildings} from "../../redux/actions/Building";
import {useHistory} from "react-router";
import {checkSuperAdminPermission} from "../../util/function/checkGroup";

const BuildingCreateButton = ({title, building={}, fetchData=()=>{}}) => {
    const [openCreate, setOpenCreate] = React.useState(false);
    const [saving, setSaving] = React.useState(false)
    const [errors, setErrors] = React.useState(false)
    const dispatch = useDispatch()
    const history = useHistory()
    const {groups} = useSelector(({auth}) => auth);
    const isHaveGroup = checkSuperAdminPermission(groups)

    const handleFetchResponse = (response) => {
        if (response.message) {
            if(response.response.status === 400){
                setErrors(response.response.data)
                setSaving(false)
            }
            else{
                dispatch(showMessage(response.message))
            }
        } else {
            setSaving(false);
            setOpenCreate(false)
            dispatch(getBuildings())
            history.push(`/app/${response.data.id}/building`)
            if (building.id){
                fetchData()
            }

        }
    }

    const handleCreateClose = (check, data) => {
        if(check){
            if(building?.id){
                fetchUpdateData(data)
            }else {
                fetchCreateData(data)
            }
        }else {
            setOpenCreate(false);
        }
    };

    const fetchCreateData = async (data) =>{
        setSaving(true)
        const response =  await apiService.create_json({data:data,url_params:{},routeName:ROUTE_NAME.BUILDING.CREATE_BUILDING})
        handleFetchResponse(response)
    }

    const fetchUpdateData = async (data) =>{
        setSaving(true)
        const response =  await apiService.update_json({data:data,url_params:{id:building.id},routeName:ROUTE_NAME.BUILDING.UPDATE_BUILDING})
        handleFetchResponse(response)
    }
    return (
            <>
            {isHaveGroup && <div className='ml-1'>
                <Button size='small' m={1} variant="outlined" className="jr-btn bg-success text-white"
                        onClick={() => setOpenCreate(true)}>
                    {title}
                </Button>
                <FormDialog handleClose={handleCreateClose} open={openCreate} title={title}
                            form={BuildingForm} maxWidth={'md'} saving={saving} errors={errors} initState={building}/>
            </div>}
            </>
    )
}

export default BuildingCreateButton
