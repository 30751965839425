import React from "react";
import {Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const FacilityBookingApp = ({match}) => (
    <div className="app-wrapper">
        <Switch>
            <Route path={`${match.url}`} component={asyncComponent(() => import('./FacilityBookings'))}/>
            <Route component={asyncComponent(() => import("app/extraPages/routes/404"))}/>
        </Switch>
    </div>
);

export default FacilityBookingApp;
