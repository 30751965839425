import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {InputAdornment, Select} from "@material-ui/core";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import {errorDJRestToString} from "../../util/function/errorsProcess";

const CustomNumberField = (props) => {
    const {fieldName, register, setValue, required=false, endAdornment = null, decimalPlaces=0, errors={}, defaultValue, minimumValue, disabled=false} = props
    const initValue = defaultValue?Number(defaultValue):0

    useEffect(()=> {
        setValue(fieldName, initValue);
    }, [fieldName])

    return (
        <CurrencyTextField

            fullWidth
            size='small'
            currencySymbol=''
            decimalPlaces={decimalPlaces}
            InputProps={{
                endAdornment: <InputAdornment position="end">{endAdornment}</InputAdornment>,
            }}

            name={fieldName}
            inputRef={(ref) => {
                if (!ref) return;
                register({name: fieldName, value: ref.value})
            }}
            onChange={(e, value) => setValue(e.currentTarget.name, value)}
            rules={{required: required}}
            error={!!errors[fieldName]}
            helperText={errorDJRestToString(errors[fieldName])}
            value={initValue}
            minimumValue={minimumValue}
            disabled={disabled}
        />
    )
}

CustomNumberField.propTypes = {
    fieldName: PropTypes.string.isRequired,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    endAdornment: PropTypes.any,
    required: PropTypes.bool,
    decimalPlaces: PropTypes.number,
    errors: PropTypes.object,
    defaultValue: PropTypes.number,
    minimumValue: PropTypes.number,
    disabled: PropTypes.bool,
};
export default CustomNumberField
