import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import IntlMessages from "../../util/IntlMessages";
import {Typography} from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import React from "react";

import BreadcrumbsSchemma from "./breadcrumbsSchema";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import {NavLink} from 'react-router-dom';


const CustomBreadcrumbs = (props) => {
    const {name} = props;
    const BreadcrumbsObject = BreadcrumbsSchemma[name];

    return (
        <Box m={2} className="ml-0 mt-0">
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} aria-label="breadcrumb">
                <NavLink color="inherit" to="/">
                    <IntlMessages id='home'/>
                </NavLink>
                {BreadcrumbsObject.links.map((link) => <NavLink color="inherit" to={link.href}>
                    {link.name}
                </NavLink>)}

                <Typography color="textPrimary">{BreadcrumbsObject.currentName}</Typography>
            </Breadcrumbs>
        </Box>
    )
}



CustomBreadcrumbs.propTypes = {
    name: PropTypes.string.isRequired
};

export default CustomBreadcrumbs
