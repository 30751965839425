import React, {useEffect, useState} from "react";
import BuildingDetailHeader from "components/Layout/Header/BuildingDetailHeader";
import Box from "@material-ui/core/Box";
import CustomBreadcrumbs from "components/Breadcrumbs";
import {useDispatch, useSelector} from "react-redux";
import {CircularProgressCenter} from "../../components/CircularProgress";
import BuildingInfo from "../../components/Layout/Info/BuildingInfo";
import apiService from "../../util/services/generalApi";
import {showMessage} from "../../redux/actions";
import {ROUTE_NAME} from "../../util/services/routes";
import ItemCard from "../../components/ListPage/Card";
import {apartmentTypeDisplaySchema} from "../../constants/cardSchema/displaySchema/apartmentTypeDisplaySchema";
import createApartmentTypeSchema from "../../constants/cardSchema/createButtonSchema/createApartmentType";
import {apartmentTypeMenuSchema} from "../../constants/cardSchema/listCardMenuSchema/ApartmentTypeMenuSchema";
import {BlockMenuSchema} from "../../constants/cardSchema/listCardMenuSchema/BlockMenuSchema";
import {blockDisplaySchema} from "../../constants/cardSchema/displaySchema/blockDisplaySchema";
import createBlockSchema from "../../constants/cardSchema/createButtonSchema/createBlock";
import CompanyInfo from "../../components/Layout/Info/CompanyInfo";
import BuildingCreateButton from "./BuildingCreateButton";

const BuildingDetail = () => {
    const dispatch = useDispatch();
    const {buildingId} = useSelector(({building}) => building);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});

    const fetchData = async () => {
        setLoading(true)
        const response = await apiService.getOne({
            url_params:{id: buildingId} , routeName: ROUTE_NAME.BUILDING.GET_BUILDING_DETAIL
        });
        if (response.message) {
            dispatch(showMessage(response.message))
        } else {
            setData(response.data);
            setLoading(false);
        }
    }

    useEffect(()=>{
        if(buildingId){
            fetchData()
        }
    }, [buildingId])


    return (
        <div className="app-wrapper">
            <Box m={2} className="m-0" display="flex"
                 flexWrap="wrap">
                <Box flexGrow={1} p={1} className='pt-0 pb-0'>
                    <CustomBreadcrumbs name='building'/>
                </Box>
                <Box flexDirection="row-reverse" display='flex' className='pb-2'>
                    <BuildingCreateButton title="Tạo dự án"/>
                    {!loading&& <BuildingCreateButton building={data} title="Cập nhật dự án" fetchData={fetchData}/>}
                </Box>
            </Box>
            {loading ? <CircularProgressCenter/> :
                <div className="row">
                    <div className="col-12">
                        <div className="jr-card pb-3">

                            <BuildingDetailHeader data={data}/>
                            <div className="jr-profile-content">
                                <div className="row">
                                    <div className="col-xl-8 col-lg-8 col-md-7 col-12">
                                        {/*<PropertyCapital property={detail}/>*/}
                                        <BuildingInfo data={data} />
                                        <ItemCard menuSchema={apartmentTypeMenuSchema} params={{building_id:buildingId}} title={''} displaySchema={apartmentTypeDisplaySchema} routeName={ROUTE_NAME.APARTMENT_TYPE.GET_APARTMENT_TYPES} schemaCreateButton={createApartmentTypeSchema}/>
                                    </div>
                                    <div className='col-xl-4 col-lg-4 col-md-5 col-12'>
                                        <ItemCard menuSchema={BlockMenuSchema} params={{building_id:buildingId}} title={''} displaySchema={blockDisplaySchema} routeName={ROUTE_NAME.BLOCK.GET_BLOCKS} schemaCreateButton={createBlockSchema}/>
                                        <CompanyInfo />
                                        {/*<PhotoCollage id={id}/>*/}
                                        {/*<Analysis property={detail}/>*/}
                                        {/*<ItemCard id={id}/>*/}

                                    </div>
                                    {/*<div className="col-12">*/}
                                    {/*    <CardBoxHeaderDivider title='Mô tả dự án'*/}
                                    {/*                          actionButton={<PropertyUpdateTextButton property={detail}*/}
                                    {/*                                                                  fieldName='description'*/}
                                    {/*                                                                  title='mô tả dự án'/>}>*/}
                                    {/*        <p className='pre-line'>{detail.description}</p>*/}
                                    {/*    </CardBoxHeaderDivider>*/}
                                    {/*    <CardBoxHeaderDivider title='Điểm mạnh thị trường'*/}
                                    {/*                          children={detail.market_strength}*/}
                                    {/*                          actionButton={<PropertyUpdateTextButton property={detail}*/}
                                    {/*                                                                  fieldName='market_strength'*/}
                                    {/*                                                                  title='điểm mạnh thị trường'/>}>*/}
                                    {/*        <p className='pre-line'>{detail.market_strength}</p>*/}
                                    {/*    </CardBoxHeaderDivider>*/}
                                    {/*</div>*/}

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            }

        </div>
    );
};

export default BuildingDetail;


