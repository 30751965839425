import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import auth from "../../util/services/authApi";
import {GET_PROFILE_USER, SIGNIN_USER, SIGNOUT_USER,} from "constants/ActionTypes";
import {getProfileSuccess, userSignInSuccess, userSignOutSuccess} from "../actions/Auth";
import {setBuildingIdDefaultSuccess, getBuildingsSuccess} from "../actions/Building";
import {showMessage} from "../actions/Common"


const signOutRequest = async () =>
    await auth.logout()
        .then(token => token)
        .catch(error => error);

const getProfileUserRequest = async () =>
    await auth.getProfile()
        .then(token => token)
        .catch(error => error);

function* signInUserWithUsernamePassword({payload}) {

    const {username, password} = payload;
    try {
        const signInUser = yield call(auth.login, username, password);
        if (signInUser.message) {
            if(signInUser.response.status === 401){
                yield put(showMessage('Sai username hoặc mật khẩu'));
            }else{
                yield put(showMessage(signInUser.message));
            }
        } else {
            yield put(userSignInSuccess(signInUser.data.access));
        }
    } catch (error) {
        yield put(showMessage(error));
    }
}

function* signOut() {
    try {
        const signOutUser = yield call(signOutRequest);
        if (signOutUser === undefined) {
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            yield put(userSignOutSuccess(signOutUser));
        } else {
            yield put(showMessage(signOutUser.message));
        }
        yield put(setBuildingIdDefaultSuccess(null))
        yield put(getBuildingsSuccess([]))
    } catch (error) {
        yield put(showMessage(error));
    }
}

function * getProfile() {
    try {
        const profile = yield call(getProfileUserRequest);
        if (profile.message) {
            yield put(showMessage(profile.message));
        } else {
            yield put(getProfileSuccess({profile: profile.data, groups: profile.data.permissions.concat(profile.data.is_admin?['is_super']:[])}));
        }
    } catch (error) {
        yield put(showMessage(error));
    }
}
// ------------------------------------------------------------------
export function* signInUser() {
    yield takeEvery(SIGNIN_USER, signInUserWithUsernamePassword);
}

export function* getProfileUser() {
    yield takeEvery(GET_PROFILE_USER, getProfile);
}

export function* signOutUser() {
    yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
    yield all([
        fork(signInUser),
        fork(getProfileUser),
        fork(signOutUser)]);
}
