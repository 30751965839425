import {
    FETCH_BLOCKS_SUCCESS,
} from "../../constants/ActionTypes";


const INIT_STATE = {
    loading: true,
    blocks: [],
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_BLOCKS_SUCCESS: {
            // if (!state.buildingId){
            //     state.buildingId = action.payload[0].id
            // }
            return {
                ...state,
                blocks: action.payload,
                loading: false,
            }
        }
        default:
            return state;
    }
}
