import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {CircularProgress, TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {setBuildingIdDefault, setBuildingType} from "../../redux/actions/Building";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiInput-underline:before': {
            borderBottomColor: 'transparent',
        },
        '& .MuiIconButton-root': {
            color: 'white',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: '1px solid white',
        },
    },

}));


const BuildingAppBarFilter = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {buildings, loading, buildingId} = useSelector(({building}) => building);

    const handleBuildingChange = (value) => {
        dispatch(setBuildingIdDefault(value?.id));
        dispatch(setBuildingType(value?.type));
    }

    return (
        <div key={buildings}>
            {loading?<CircularProgress color="inherit" size={20} /> :
                <Autocomplete
                    disableClearable={true}
                    defaultValue={buildings.find(element => buildingId?element.id === buildingId:true)}
                    options={buildings}
                    loading={loading}
                    loadingText='Đang tải'
                    autoHighlight
                    size="small"
                    onChange={(e, value) => handleBuildingChange(value)}
                    getOptionLabel={option => option.name
                    }
                    renderOption={option => option.name}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            // label="Tòa nhà"
                            variant="standard"
                            style={{ width: 250}}

                            classes={classes}
                            InputProps={{
                                ...params.InputProps,
                                className:'text-white',
                                startAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}

                />}
        </div>

    )
}

export default BuildingAppBarFilter
