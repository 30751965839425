import {FIXED_DRAWER, INSIDE_THE_HEADER, SWITCH_LANGUAGE, VERTICAL_NAVIGATION} from 'constants/ActionTypes';
import {DEEP_PURPLE} from 'constants/ThemeColors';

const initialSettings = {
  drawerType: FIXED_DRAWER,
  themeColor: DEEP_PURPLE,
  darkTheme: false,
  width: window.innerWidth,
  navigationStyle: VERTICAL_NAVIGATION,
  horizontalNavPosition: INSIDE_THE_HEADER,
  locale: {
    languageId: 'vietnam',
    locale: 'vi',
    name: 'Việt Nam',
    icon: 'vn'
  }
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: {...state.locale, locale: action.payload},

      };


    default:
      return state;
  }
};

export default settings;
