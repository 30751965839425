import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from "prop-types";

const ItemCardMenu = (props) => {

    const {menuState, anchorEl, handleRequestClose, id, menuList} = props;

    return (
        <Menu id="long-menu"
              anchorEl={anchorEl}
              open={menuState}
              onClose={handleRequestClose}

              MenuListProps={{
                  style: {
                      width: 150,
                      paddingTop: 0,
                      paddingBottom: 0
                  },
              }}>
            {menuList.map((item, index) =>
                <MenuItem key={item.key} onClick={() => handleRequestClose(item.key)}>
                    {item.name}
                </MenuItem>,
            )}
        </Menu>
    );
};

ItemCardMenu.propTypes = {
    menuState: PropTypes.bool.isRequired,
    anchorEl: PropTypes.any.isRequired,
    handleRequestClose: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    menuList: PropTypes.array.isRequired,
};

export default ItemCardMenu;
