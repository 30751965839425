import React, {useEffect} from "react";
import {Grid, TextField, Typography} from '@material-ui/core'
import {errorDJRestToString} from "util/function/errorsProcess";
import {useSelector} from "react-redux";
import IntlMessages from "../../../util/IntlMessages";


const BlockForm = ({register, setValue, initState = {}, errors = {}}) => {

    const {buildingId} = useSelector(({building}) => building);
    useEffect(() => {
        register({name:'building_id'});
        setValue('building_id', buildingId);
    }, [buildingId])

    return (
        <Grid container spacing={4}>

            <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" gutterBottom={true}><IntlMessages id={'block_name'}/></Typography>
                <TextField inputRef={register} name="name" fullWidth error={!!errors.name}
                           helperText={errorDJRestToString(errors.name)} defaultValue={initState.name}/>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom={true}>Mô tả</Typography>
                <TextField inputRef={register} name="description" fullWidth error={!!errors.description}
                           helperText={errorDJRestToString(errors.description)} defaultValue={initState.description}
                           multiline variant='outlined'/>
            </Grid>


        </Grid>
    )
}

export default BlockForm
