import React from "react";
import {Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const MaintenanceApp = ({match}) => (
    <div className="app-wrapper">
        <Switch>
            <Route path={`${match.url}/task`} component={asyncComponent(() => import('./MaintenanceTasks'))}/>
            <Route path={`${match.url}/maintenance`} component={asyncComponent(() => import('./Maintenances'))}/>
            {/*<Route component={asyncComponent(() => import("app/extraPages/routes/404"))}/>*/}
        </Switch>
    </div>
);

export default MaintenanceApp;
