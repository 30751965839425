import ApartmentTypeForm from "../../../components/Forms/ApartmentTypeForm";
import {ROUTE_NAME} from "../../../util/services/routes";
import {checkApartmentTypeCreatePermission} from "../../../util/function/checkGroup";
import IntlMessages from "../../../util/IntlMessages";
import React from 'react';

const createApartmentTypeSchema = {
    createButtonTitle: <IntlMessages id={'apartment_type_create'}/>,
    createForm: ()=>ApartmentTypeForm,
    routeCreateName: ROUTE_NAME.APARTMENT_TYPE.CREATE_APARTMENT_TYPE,
    checkCreateGroup: checkApartmentTypeCreatePermission
}
export default createApartmentTypeSchema
