import React, {useEffect} from "react";
import AppLayouts from "./AppLayouts";
import Routes from "../../app";
import {useHistory, useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {getBuilding, getCompany, setBuildingIdDefault, setBuildingType} from "../../redux/actions/Building";
import {fetchBlocks} from "../../redux/actions/Blocks";

const AppLayout = () => {
    const history = useHistory()
    const {building_id} = useParams();
    const {buildings} = useSelector(({building}) => building);
    const dispatch = useDispatch()

    useEffect(() => {
        if (building_id === 'null' && !!buildings) {
            let buildingId = buildings[0]?.id
            if (buildingId) {
                dispatch(setBuildingIdDefault(buildingId));
                dispatch(setBuildingType(buildings[0]?.type));
                history.push(`/app/${buildingId}/building`);
            }
        }
        else {
            // history.push(`/app/${building_id}/fee`);
            if(buildings.length>0){
                let b = buildings.filter( v => v.id == building_id)[0]
                dispatch(setBuildingType(b?.type));
            }
            dispatch(setBuildingIdDefault(building_id))
        }
    }, [buildings, building_id])

    useEffect(() => {
        if(building_id !== 'null'){
            dispatch(fetchBlocks({'building_id': building_id}));
            dispatch(getBuilding({'id': building_id}));
        }
    }, [building_id])

    useEffect(()=>{

        dispatch(getCompany());
    },[])

    const Layout = AppLayouts["Vertical"];
    return <Layout>
        <Routes/>
    </Layout>
};

export default AppLayout;
