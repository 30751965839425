import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import Settings from './Settings';
import Auth from './Auth';
import Building from './Building';
import Block from './Block';
import Common from './Common';
import Responsive from './Responsive';


export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  building: Building,
  block: Block,
  commonData: Common,
  responsive : Responsive,
});
