import React, {useState} from "react";
import {Avatar, IconButton} from "@material-ui/core";
import apiService from "../../../util/services/generalApi";
import {ROUTE_NAME} from "../../../util/services/routes";
import {showMessage} from "../../../redux/actions";
import {useDispatch} from "react-redux";
import UploadImageServiceDialog from "../../Dialog/UploadImageServiceDialog";
import {useHistory} from "react-router";
import IntlMessages from "../../../util/IntlMessages";

const propertyHeaderStyle = {
    'background-image': "linear-gradient(to right,#725ef6, #6001d1)",
    'background-color': '#1fd1f9'
};

const BuildingDetailHeader = ({data}) => {
    const {apartment_count, block_count, apartment_type_count, name, id, image} = data
    const dispatch = useDispatch()
    const [saving, setSaving] = useState(false)
    const [openUpload, setOpenUpload] = useState(false)
    const [imageShow, setImageShow] = useState(image)
    const history = useHistory()

    const fetchUpdateBuildingImage = async (json) => {
        const response = await apiService.update_json(
            {data:{image:json},url_params:{id:id},routeName:ROUTE_NAME.BUILDING.UPDATE_BUILDING}
        )
        if (response.message) {
            dispatch(showMessage(response.message))
        }else {
            history.push(window.location.pathname + window.location.search)
            setImageShow(json)
            setSaving(false)
            setOpenUpload(false)
        }
    }
    const handleCloseUpload = (check, json) => {
        if (check){
            fetchUpdateBuildingImage(json)
        }else {
            setOpenUpload(false)
        }
    }

    return (
        <div className="jr-profile-banner" style={propertyHeaderStyle}>
            <div className="jr-profile-container">
                <div className="jr-profile-banner-top mb-0">
                    <div className="jr-profile-banner-top-left">
                        <div className="jr-profile-banner-avatar">
                            <IconButton className='p-0' onClick={() => setOpenUpload(true)}>
                                <Avatar className="size-90" alt="..."
                                        src={imageShow?.image_thumb}/>
                            </IconButton>
                        </div>
                        <div className="jr-profile-banner-avatar-info">
                            <h2 className="mb-2 jr-mb-sm-3 jr-fs-xxl jr-font-weight-light">{name}</h2>
                        </div>
                    </div>
                    <div className="jr-profile-banner-top-right">
                        <ul className="jr-follower-list">
                            <li>
                                <span className="jr-follower-title jr-fs-lg jr-font-weight-medium">{Number(apartment_count).toLocaleString()}</span>
                                <span className="jr-fs-sm"><IntlMessages id={'apartment'}/></span></li>
                            <li>
                                <span className="jr-follower-title jr-fs-lg jr-font-weight-medium">{Number(block_count).toLocaleString()}</span>
                                <span className="jr-fs-sm"><IntlMessages id={'block'}/></span></li>
                            <li>
                                <span className="jr-follower-title jr-fs-lg jr-font-weight-medium">{Number(apartment_type_count).toLocaleString()}</span>
                                <span className="jr-fs-sm"><IntlMessages id={'apartment_type'}/></span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <UploadImageServiceDialog handleClose={handleCloseUpload} open={openUpload} title={'Cập nhật hình Building'} single={true} saving={saving} setSaving={setSaving}/>
        </div>
    )
}

export default BuildingDetailHeader;

