import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    userSignIn,
} from 'redux/actions/Auth';
import {hideMessage, showLoader} from "redux/actions/Common";

const SignIn = (props) => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const {loader, token} = useSelector(({auth}) => auth);
    const {alertMessage, showMessage} = useSelector(({commonData}) => commonData)

    useEffect(() => {
        if (showMessage) {
            setTimeout(() => {
                dispatch(hideMessage());
            }, 100);
        }
        if (token) {
            props.history.push('/');
        }
    }, [showMessage, token, props.history, dispatch]);


    return (
        <div
            className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
            <div className="app-login-main-content">

                <div className="app-logo-content d-flex align-items-center justify-content-center">
                    <Link className="logo-lg" to="/" title="Houze invest">
                        {/*<img src={require("assets/images/svg-houzepay.svg")} alt="" title="" className='mw-100'/>*/}
                        <h1 className='text-white font-weight-bold font-size-60 mb-0'>Houze</h1>
                        <h1 className='text-white  font-size-30 float-right mt-n2'>Building</h1>
                    </Link>
                </div>

                <div className="app-login-content">
                    <div className="app-login-header mb-4">
                        <h1>Đăng nhập</h1>
                    </div>

                    <div className="app-login-form">
                        <form onKeyDown={(event) => {
                          if (event.key === 'Enter'){
                            dispatch(showLoader());
                            dispatch(userSignIn({username, password}));
                          }
                        }}>
                            <fieldset>
                                <TextField
                                    label='Tên đăng nhập'
                                    fullWidth
                                    onChange={(event) => setUsername(event.target.value)}
                                    defaultValue={username}
                                    margin="normal"
                                    className="mt-1 my-sm-3"
                                />
                                <TextField
                                    type="password"
                                    label='Mật khẩu'
                                    fullWidth
                                    onChange={(event) => setPassword(event.target.value)}
                                    defaultValue={password}
                                    margin="normal"
                                    className="mt-1 my-sm-3"
                                />

                                <div className="mb-3 d-flex align-items-center justify-content-between">
                                    <Button onClick={() => {
                                        dispatch(showLoader());
                                        dispatch(userSignIn({username, password}));
                                    }} variant="contained" color="primary">
                                        Đăng nhập
                                    </Button>

                                    {/*<Link to="/signup">*/}
                                    {/*  <IntlMessages id="signIn.signUp"/>*/}
                                    {/*</Link>*/}
                                </div>

                            </fieldset>
                        </form>
                    </div>
                </div>

            </div>
            {
                loader &&
                <div className="loader-view">
                    <CircularProgress/>
                </div>
            }
            {showMessage && NotificationManager.error(alertMessage)}
            <NotificationContainer/>
        </div>
    );
};


export default SignIn;
