import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import UserInfo from 'components/UserInfo';
import {updateWindowWidth} from 'redux/actions'
import SideBarContent from "./SideBarContent";
import {Image} from "@material-ui/icons";

const SideBar = ({navCollapsed, onToggleCollapsedNav}) => {
  const dispatch = useDispatch();
  const {width} =  useSelector(({responsive}) => responsive);

  useEffect(() => {
    window.addEventListener('resize', () => {
      dispatch(updateWindowWidth(window.innerWidth))
    });
  }, []);


  let type = width < 1200?'temporary':'permanent' ;

  return (
    <div className={`app-sidebar d-none d-xl-flex`}>
      <Drawer className="app-sidebar-content"
                variant={type}
                open={type.includes('temporary') ? navCollapsed : true}
                onClose={onToggleCollapsedNav}
                classes={{
                  paper: 'side-nav',
                }}
      >
          <div className="app-logo d-flex flex-row align-items-center app-toolbar">
              <img src={require("assets/images/Houze-logo-icon.svg")} />
              <img src={require("assets/images/Houze-logo-text.svg")} />
          </div>
        <UserInfo/>
        <SideBarContent/>
      </Drawer>
    </div>
  );
};


export default withRouter(SideBar);

