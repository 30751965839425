import React from "react"

export const BUILDING_STATUS_SALE_DICT = {
    DISABLE: 1,
    ALLOW: 0,
}

export const BUILDING_STATUS_SALE_DISPLAYS_DICT = {
    [BUILDING_STATUS_SALE_DICT.DISABLE]: 'Khóa đăng bán',
    [BUILDING_STATUS_SALE_DICT.ALLOW]: 'Cho phép đăng bán',
}

export const BUILDING_TYPE_DICT = {
    APARTMENT: 0,
    TOWNHOUSE: 1,
}


export const BUILDING_TYPE_DISPLAYS_DICT = {
    [BUILDING_TYPE_DICT.APARTMENT]: 'Chung cư',
    [BUILDING_TYPE_DICT.TOWNHOUSE]: 'Khu dân cư',
}
