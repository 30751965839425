import axiosInstance from "../axiosApi";
import {routeTo} from './routes';
import {objectToFormData, objectToJsonData} from "../function/objectToFormData";
const FileDownload = require('js-file-download');

export default {
    // called when the user attempts to log in
    getList: async ({filters, routeName, url_params={}}) => {
        return await axiosInstance.get(routeTo(routeName, filters, url_params)).then(response => {
            return response
        }).catch(error => error)
    },
    getOne: async ({url_params, routeName}) => {
        return await axiosInstance.get(routeTo(routeName, {}, url_params)).then(response => {
            return response
        }).catch(error => error)
    },
    update: async ({data, url_params={}, routeName}) => {
        return await axiosInstance.patch(routeTo(routeName, {}, url_params), objectToFormData(data), {headers: { 'content-type': 'multipart/form-data' }}).then(response => {
            return response
        }).catch(error => error)
    },
    update_json: async ({data, url_params={}, routeName}) => {
        return await axiosInstance.patch(routeTo(routeName, {}, url_params), data).then(response => {
            return response
        }).catch(error => error)
    },
    delete: async ({url_params, routeName}) => {
        return await axiosInstance.delete(routeTo(routeName, {}, url_params)).then(response => {
            return response
        }).catch(error => error)
    },

    create: async ({data, url_params={}, routeName}) => {
        return await axiosInstance.post(routeTo(routeName, {}, url_params), objectToFormData(data), {headers: { 'content-type': 'multipart/form-data' }}).then(response => {
            return response
        }).catch(error => error)
    },

    create_json: async ({data, url_params={}, routeName}) => {
        return await axiosInstance.post(routeTo(routeName, {}, url_params), objectToJsonData(data)).then(response => {
            return response
        }).catch(error => error)
    },
    download: async ({filters, routeName, exportName='export'}) => {
        return await axiosInstance.get(routeTo(routeName, filters), {responseType:'blob', headers: { 'accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'}}).then(({data}) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([data]));

            const link = document.createElement('a');

            link.href = downloadUrl;

            link.setAttribute('download', `${exportName}.xlsx`); //any other extension

            document.body.appendChild(link);

            link.click();

            link.remove();
        }).catch(error => error)
    },
    post_download: async ({data, url_params={}, routeName, exportName='export'}) => {
        return await axiosInstance.post(routeTo(routeName, {}, url_params), objectToJsonData(data),{headers: { 'accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9;charset=UTF-8'}}).
        then(({data}) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), data],  { type: "text/plain;charset=utf-8" }));

            const link = document.createElement('a',{});
            link.setAttribute('target', '_blank');

            link.href = downloadUrl;

            link.setAttribute('download', `${exportName}.csv`); //any other extension

            document.body.appendChild(link);

            link.click();

            link.remove();
        }).catch(error => error)
    },
};
