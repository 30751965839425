import {Grid, Typography} from "@material-ui/core";
import React from "react";
import Pagination from '@material-ui/lab/Pagination';

import PropTypes from "prop-types";

const CustomPagination = ({count, pageSize, page, handleChangePage}) => {

    return (
        <div className="pt-3">
            <Grid
                justify="space-between" // Add it here :)
                container
                spacing={2}
            >
                <Grid item>
                    <Typography type="title" color="primary">
                        {count}
                    </Typography>
                </Grid>

                <Grid item>
                    <Pagination count={Math.ceil(count / pageSize)}
                                color="primary"
                                onChange={handleChangePage} page={page?parseInt(page):1}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

CustomPagination.propTypes = {
    count: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    page: PropTypes.any.isRequired,
    handleChangePage: PropTypes.func.isRequired
};

export default CustomPagination
