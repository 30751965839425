import React from "react";
import {Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const DashboardApp = ({match}) => (
    <div className="app-wrapper">
        <Switch>
            <Route path={`${match.url}/general`} component={asyncComponent(() => import('./DashBoards'))}/>
            <Route path={`${match.url}/ticket`} component={asyncComponent(() => import('./DashBoards/ticketDashboard'))}/>
            <Route component={asyncComponent(() => import("app/extraPages/routes/404"))}/>
        </Switch>
    </div>
);

export default DashboardApp;
