import React, {useEffect, useState} from 'react';
import IconButton from "@material-ui/core/IconButton";
import ItemsRow from "./ItemsRow";
import FormDialog from "../../Dialog/FormDialog";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import {useHistory} from "react-router";
import {showMessage} from "../../../redux/actions";
import apiService from "../../../util/services/generalApi";
import ConfirmDialog from "../../Dialog/ConfirmDialog";
import UploadImagesDialog from "../../Dialog/UploadImagesDialog";
import {CircularProgressCenter} from "../../CircularProgress";
import useQueryString from "../../../util/hook/useQueryString";
import CustomPagination from "../../Pagination";

const ItemCard = ({menuSchema, displaySchema, title, routeName, params = {}, schemaCreateButton = {}, initData = {}, url_params = {}, paging = false, pagination=false, pageSize=10}) => {
    const dispatch = useDispatch();
    const history = useHistory()
    const [data, setData] = useState({})
    const [loader, setLoader] = useState(true)
    const {groups} = useSelector(({auth}) => auth);
    const {createButtonTitle, createForm, routeCreateName, checkCreateGroup} = schemaCreateButton
    let isHaveCreateGroup = checkCreateGroup ? checkCreateGroup(groups) : false

    const [currentAction, setCurrentAction] = useState(null)

    const [dialogTitle, setDialogTitle] = useState('')
    const [dialogMessage, setDialogMessage] = useState('')
    const [saving, setSaving] = useState(false)
    const [currentData, setCurrentData] = useState({})
    const [errors, setErrors] = useState({})

    const [form, setForm] = useState(null)
    const [openForm, setOpenForm] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false)
    const [openImageForm, setOpenImageForm] = useState(false)
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleFetchResponse = (response) => {
        if (response.message) {
            if (response.response.status === 400) {
                setErrors(response.response.data)
                setSaving(false)
            } else {
                dispatch(showMessage(response.message))
            }
        } else {
            fetchData();
            setErrors({})
            setSaving(false);
            setOpenForm(false);
            setOpenImageForm(false);
            setOpenConfirm(false);
        }
    }

    const fetchUpdateData = async (data, url_params, routeName) => {
        setSaving(true)
        const response = await apiService.update({data, url_params, routeName})
        handleFetchResponse(response)
    }

    const fetchDelete = async (url_params, routeName) => {
        setSaving(true)
        const response = await apiService.delete({url_params, routeName})
        handleFetchResponse(response)
    }

    const fetchCreateData = async (data, routeName) => {
        setSaving(true)
        const response = await apiService.create({data, url_params, routeName})
        handleFetchResponse(response)
    }

    const fetchData = async () => {
        setLoader(true);
        const response = await apiService.getList({filters: {...params, offset: (page - 1) * pageSize, limit:pageSize}, routeName: routeName, url_params:url_params});
        if (response.message) {
            dispatch(showMessage(response.message))
        } else {
            if (paging) {
                setData(response.data.results);
                setCount(response.data.count);
            } else {
                setData(response.data);
            }
            setLoader(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [page]);


    const pushAction = (action, data) => {
        if (action in menuSchema) {
            setCurrentAction(action);
            let actionObject = menuSchema[action];
            switch (actionObject.type) {
                case 'update':
                    setOpenForm(true);
                    setForm(actionObject.form);
                    setDialogTitle(actionObject.title);
                    break;
                case 'confirm':
                    setOpenConfirm(true);
                    setDialogTitle(actionObject.title);
                    setDialogMessage(actionObject.desc(data));
                    break
                case 'update_image':
                    setOpenImageForm(true);
                    setDialogTitle(actionObject.title);
                    break
                case 'redirect':
                    history.push(actionObject.url(data));
                    break
                case 'new_tab':
                    const win = window.open(actionObject.url(data), "_blank");
                    win.focus();

            }
        }
    }

    const handleFormClose = (check, data) => {
        setSaving(true);
        if (check) {
            if (currentAction === 'create') {
                fetchCreateData(data, routeCreateName)
            } else {
                let actionObject = menuSchema[currentAction];
                if (actionObject.type === 'update') {
                    fetchUpdateData(data, actionObject.urlParams(currentData), actionObject.routeName)
                }
            }

        } else {
            setOpenForm(false);
            setForm(null);
            setSaving(false);
            setErrors({})
        }
    }

    const handleDialogConfirm = (check) => {
        setSaving(true);
        if (check) {
            let actionObject = menuSchema[currentAction];
            if (actionObject.action === 'delete'){
                fetchDelete(actionObject.urlParams(currentData), actionObject.routeName)
            } else{
                fetchUpdateData(actionObject.data, actionObject.urlParams(currentData), actionObject.routeName)
            }

        } else {
            setOpenConfirm(false);
            setSaving(false);
        }
    }

    const handleOpenCreate = () => {
        setCurrentAction('create')
        setOpenForm(true);
        setForm(createForm);
        setDialogTitle(createButtonTitle);
        setCurrentData({})
    }
    const handleUpdateImageClose = (check, pictures) => {
        if (check) {
            if (pictures.length > 0) {
                let actionObject = menuSchema[currentAction];
                fetchUpdateData({[actionObject.image_field]: pictures[0]}, actionObject.urlParams(currentData), actionObject.routeName)
            }
        } else {
            setOpenImageForm(false);
            setSaving(false);
        }
    };


    return (
        <div className="jr-card p-3">

            <div className="table-responsive-material">
                <table className="default-table table-unbordered table table-sm table-hover">
                    <thead className='th-border-b'>
                    <tr>
                        {!!title&&<th className='' colSpan={displaySchema.length}>{title}</th>}
                        {!title&&displaySchema.map(obj => <th className='text-center'>{obj.title}</th>)}
                        <th className="text-right">
                            {isHaveCreateGroup && <IconButton size='medium' className="icon-btn text-light p-0"
                                                              onClick={() => handleOpenCreate()}><i
                                className="zmdi zmdi-plus-circle-o text-primary"/></IconButton>}

                        </th>
                    </tr>
                    </thead>
                    {loader && !data?.length>0 ? <CircularProgressCenter/> :
                        <tbody>
                        {data.map(data => {
                            return (
                                <ItemsRow key={data.id} data={data} displaySchema={displaySchema}
                                          menuSchema={menuSchema} setCurrentData={setCurrentData}
                                          pushAction={pushAction}/>
                            );
                        })}
                        </tbody>
                    }

                </table>
            </div>

            {pagination && <CustomPagination count={count} handleChangePage={handleChangePage}
                                             pageSize={pageSize} page={page}/>}
            {openForm && <FormDialog handleClose={handleFormClose} open={openForm} title={dialogTitle}
                                     form={form} maxWidth={'sm'} saving={saving} initState={currentData}
                                     errors={errors} initData={initData}/>}

            <ConfirmDialog handleClose={handleDialogConfirm} message={dialogMessage} open={openConfirm}
                           title={dialogTitle} saving={saving}/>

            <UploadImagesDialog handleClose={handleUpdateImageClose} open={openImageForm} title={dialogTitle}
                                single={true} saving={saving}/>
        </div>
    )
}

ItemCard.propTypes = {
    menuSchema: PropTypes.object.isRequired,
    displaySchema: PropTypes.array.isRequired,
    title: PropTypes.string,
    routeName: PropTypes.string.isRequired,
    params: PropTypes.object,
    schemaCreateButton: PropTypes.object,
    url_params: PropTypes.object,
    pagination: PropTypes.bool,
    pageSize: PropTypes.number
}

export default ItemCard;
