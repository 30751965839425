import React, {useEffect, useState} from 'react';
import IconButton from '@material-ui/core/IconButton'
import UploadImagesDialog from "../../Dialog/UploadImagesDialog";
import {useDispatch, useSelector} from "react-redux";
import apiService from "../../../util/services/generalApi";
import {showMessage} from "../../../redux/actions";
import {ROUTE_NAME} from "../../../util/services/routes";
import {Fab} from "@material-ui/core";
import FormDialog from "../../Dialog/FormDialog";
import CompanyForm from "../../Forms/CompanyForm";
import {checkAdminPermission} from "../../../util/function/checkGroup";

const CompanyInfo = () => {

    const [openUpload, setOpenUpload] = React.useState(false);
    const [openForm, setOpenForm] = React.useState(false);
    const {company} = useSelector(({building}) => building);
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(true)
    const [data, setData] = useState({})
    const [saving, setSaving] = useState(false)
    const [errors, setErrors] = useState({})
    const {groups} = useSelector(({auth}) => auth);
    const isHaveGroup = checkAdminPermission(groups)

    const [title, setTitle] = useState('')
    const [imageField, setImageField] = useState('')

    const handleUploadClose = (check, pictures) => {
        if (check && pictures.length > 0){
            fetchUpdateData({[imageField]:pictures[0]})
        } else {
            setOpenUpload(false);
        }

    };
    const fetchData = async () => {
        setLoader(true);
        const response = await apiService.getList({filters: {}, routeName: ROUTE_NAME.COMPANY.GET_COMPANY});
        if (response.message) {
            dispatch(showMessage(response.message))
        } else {
            setData(response.data);
            setLoader(false);
        }
    }
    useEffect(()=>{
        fetchData();
    },[company])

    const handleFetchResponse = (response) => {
        if (response.message) {
            if(response.response.status === 400){
                setErrors(response.response.data)
                setSaving(false)
            }
            else{
                dispatch(showMessage(response.message))
            }
        } else {
            fetchData();
            setSaving(false);
            setOpenUpload(false)
            setOpenForm(false)
        }
    }

    const fetchUpdateData = async (data) =>{
        setSaving(true)
        const response =  await apiService.update({data:data,url_params:{},routeName:ROUTE_NAME.COMPANY.UPDATE_COMPANY})
        handleFetchResponse(response)
    }

    const setOpenUploadImage = () => {
        setTitle('Cập nhật hình đại diện');
        setImageField('image')
        setOpenUpload(true)
    }

    const setOpenUploadCover = () => {
        setTitle('Cập nhật hình nền');
        setImageField('cover')
        setOpenUpload(true)
    }

    const handleFormClose = (check, data) => {
        setSaving(true);
        if(check){
            fetchUpdateData(data)

        }else {
            setOpenForm(false);
            setSaving(false);
        }
    }

    return (

        <div className="jr-card text-center">
            <div className={`jr-card-header-color background-image-blur`} style={{backgroundImage:`url(${data.cover_thumb})`}}>
                <div className="jr-card-header-top">
                    {/*<IconButton className="jr-fs-lg text-white" aria-label="Menu"><i className="zmdi zmdi-menu"/></IconButton>*/}
                    {isHaveGroup&&<IconButton className="icon-btn p-1 ml-auto" onClick={setOpenUploadCover}><i className="zmdi zmdi-collection-image"/></IconButton>}
                </div>

                <img className="rounded-circle size-90 avatar-shadow mb-3"
                     src={data.image_thumb} alt="Team Member" onClick={isHaveGroup&&setOpenUploadImage} />

                <div className="jr-card-hd-content">
                    <h5 className="mb-0 text-white">{data.name}</h5>
                    {/*<p className="jr-fs-sm mb-0 text-grey text-lighten-2">Graphic Designer</p>*/}
                </div>
                {isHaveGroup && <Fab className="jr-badge-up bg-success" onClick={()=>setOpenForm(true)}><i className="zmdi zmdi-border-color"/></Fab>}
            </div>
            <div className="jr-card-body pt-2">
                <p className="card-text">{data.welcome}</p>
            </div>
            <FormDialog handleClose={handleFormClose} open={openForm} title='Cập nhật công ty'
            form={CompanyForm} maxWidth={'sm'} saving={saving} initState={data} errors={errors}/>
            <UploadImagesDialog handleClose={handleUploadClose} open={openUpload} title={title} single={true} saving={saving}/>
        </div>

    );
};
export default CompanyInfo;
