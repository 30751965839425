import React from "react";

export const FEE_TYPE_DICT = {
    FEE_MANAGE: 0,
    FEE_RENT: 1,
    FEE_WATER: 2,
    FEE_ELECTRIC: 3,
    FEE_SERVICE: 4,
    FEE_OTHER: 5,
    FEE_PARK: 6,
    FEE_GAS: 7,
}

export const FEE_TYPE_DISPLAY_DICT = {
    [FEE_TYPE_DICT.FEE_MANAGE]: 'Phí quản lý',
    [FEE_TYPE_DICT.FEE_RENT]: 'Phí thuê',
    [FEE_TYPE_DICT.FEE_WATER]: 'Phí nước',
    [FEE_TYPE_DICT.FEE_ELECTRIC]: 'Phí điện',
    [FEE_TYPE_DICT.FEE_SERVICE]: 'Phí dịch vụ',
    [FEE_TYPE_DICT.FEE_OTHER]: 'Phí khác',
    [FEE_TYPE_DICT.FEE_PARK]: 'Phí giữ xe',
    [FEE_TYPE_DICT.FEE_GAS]: 'Phí gas',
}

export const FEE_STATUS_DICT = {
    UNSENT_BILL: 0,
    BILL_SENT: 1,
    PENDING: 2,
    RECEIPT_SENT: 3,
}


export const FEE_STATUS_DISPLAY_DICT = {
    [FEE_STATUS_DICT.UNSENT_BILL]: 'Chưa gửi phí',
    [FEE_STATUS_DICT.BILL_SENT]: 'Đã gửi phí',
    [FEE_STATUS_DICT.PENDING]: 'Chờ thanh toán',
    [FEE_STATUS_DICT.RECEIPT_SENT]: 'Đã thanh toán',
}



export const FEE_STATUS_STYLE_DICT = {
    [FEE_STATUS_DICT.UNSENT_BILL]: 'bg-dark text-white',
    [FEE_STATUS_DICT.BILL_SENT]: 'bg-sent-bill text-white',
    [FEE_STATUS_DICT.PENDING]: 'bg-pending-bill text-white',
    [FEE_STATUS_DICT.RECEIPT_SENT]: 'bg-paid-bill text-white',
}



export const MONTH_DISPLAY_DICT = {
    1: 'Tháng 1',
    2: 'Tháng 2',
    3: 'Tháng 3',
    4: 'Tháng 4',
    5: 'Tháng 5',
    6: 'Tháng 6',
    7: 'Tháng 7',
    8: 'Tháng 8',
    9: 'Tháng 9',
    10: 'Tháng 10',
    11: 'Tháng 11',
    12: 'Tháng 12',
}


export const FEE_SOLUTION_DICT = {
    MANUAL_CREATE: 0,
    CONFIG_CREATE: 1,
    IMPORT_CREATE: 3,
}
