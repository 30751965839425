import enLang from './entries/en-US';
import viLang from './entries/vi-VN';
import viLangNhaPho from './entries/vi-VN-nha-pho';

const AppLocale = {
  en: enLang,
  vi: viLang,
  vinp: viLangNhaPho,
};

export default AppLocale;
