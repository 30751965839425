import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import MailNotification from "../../../../components/MailNotification";
import AppNotification from "../../../../components/AppNotification";
import CardHeader from "components/dashboard/Common/CardHeader/index";
import {switchLanguage} from "redux/actions/Setting";
import LanguageSwitcher from "components/LanguageSwitcher/index";
import BuildingAppBarFilter from "../../../../components/SearchBox/building";
import {getBuildings} from "../../../../redux/actions/Building";
import {Badge} from "@material-ui/core";
import {getNotifications} from "../../../../redux/actions";

const Index =({onToggleCollapsedNav})=> {

  const dispatch = useDispatch();
  const { locale } = useSelector(({settings}) => settings);
  const [langSwitcher,setLangSwitcher]=useState(false);
  const [mailNotification,setMailNotification]=useState(false);
  const [appNotification,setAppNotification]=useState(false);
  const {comment_count, comments=[]} = useSelector(({commonData}) => commonData)

  const onAppNotificationSelect = () => {
    setAppNotification(!appNotification)
  };

  const onMailNotificationSelect = () => {
    setMailNotification(!mailNotification)
  };
  const onLangSwitcherSelect = (event) => {
    setLangSwitcher(!langSwitcher);
  };

  const handleRequestClose = () => {
    setLangSwitcher(false);
    setMailNotification(false);
  };

  const onSwitchLanguage = (lang) => {
    dispatch(switchLanguage(lang))
  };

  useEffect(() => {
    dispatch(getNotifications())
    const interval = setInterval(() => {
      dispatch(getNotifications())
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  useEffect(()=>{
    dispatch(getBuildings());
  },[])

    return (
      <AppBar className="app-main-header">
        <Toolbar className="app-toolbar" disableGutters={false}>

          <IconButton className={`jr-menu-icon mr-3 d-block d-xl-none`} aria-label="Menu"
                      onClick={onToggleCollapsedNav}>
            <span className="menu-icon"/>
          </IconButton>

          {/*<Link className="app-logo mr-2 d-none d-sm-block" to="/">*/}
          {/*    <img src={require("assets/images/svg-houzepay.svg")} alt="Jambo" title="Jambo"/>*/}
          {/*</Link>*/}
          <BuildingAppBarFilter/>

          <ul className="header-notifications list-inline ml-auto">
            <li className="list-inline-item">
              <IconButton className="icon-btn">
                <i className={`flag flag-24 flag-${locale.icon}`}/>
              </IconButton>


            </li>
            <li className="list-inline-item app-tour">
              <Dropdown
                className="quick-menu"
                isOpen={appNotification}
                toggle={onAppNotificationSelect}>

                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">

                <Badge badgeContent={comment_count} color="error" className={`animated infinite ${comment_count&&!appNotification&&'wobble'}`} max={99}>
                  <IconButton className="icon-btn">
                    <i className="zmdi zmdi-notifications-none

                    "/>
                  </IconButton>
                </Badge>
              </DropdownToggle>

                <DropdownMenu right>
                  <CardHeader styleName="align-items-center"
                              heading='Phản hồi yêu cầu'/>
                  <AppNotification notifications={comments}/>
                </DropdownMenu>
              </Dropdown>
            </li>
            <li className="list-inline-item mail-tour">
              <Dropdown
                className="quick-menu"
                isOpen={mailNotification}
                toggle={onMailNotificationSelect}
              >
                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">

                  <IconButton className="icon-btn">
                    <i className="zmdi zmdi-comment-alt-text zmdi-hc-fw"/>
                  </IconButton>
                </DropdownToggle>


                <DropdownMenu right>
                  <CardHeader styleName="align-items-center"
                              heading='Tin mới'/>
                  <MailNotification/>
                </DropdownMenu>
              </Dropdown>
            </li>

          </ul>

          <div className="ellipse-shape"/>
        </Toolbar>
      </AppBar>
    );
  };


export default withRouter(Index);
