import React from "react";
import {Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const ForumApp = ({match}) => (
    <div className="app-wrapper">
        <Switch>
            <Route path={`${match.url}/poll/:poll_id`} component={asyncComponent(() => import('./PollDetail'))}/>
            <Route path={`${match.url}/poll`} component={asyncComponent(() => import('./Poll'))}/>
            <Route path={`${match.url}/thread/:thread_id`} component={asyncComponent(() => import('./ThreadDetail'))}/>
            <Route path={`${match.url}/thread`} component={asyncComponent(() => import('./Thread'))}/>
            <Route path={`${match.url}/black-list`} component={asyncComponent(() => import('./UserBlacklist'))}/>
            <Route component={asyncComponent(() => import("app/extraPages/routes/404"))}/>
        </Switch>
    </div>
);

export default ForumApp;
