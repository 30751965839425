import React from "react";
import IntlMessages from "../../util/IntlMessages";

const BreadcrumbsSchemma = {
    account: {
        links: [],
        currentName: 'Tài khoản'

    },
    fees: {
        links: [],
        currentName: 'Phí'

    },
    fee_imports: {
        links: [],
        currentName: 'Import Phí'

    },
    receipt_imports: {
        links: [],
        currentName: 'Import Biên nhận'

    },
    receipts: {
        links: [],
        currentName: 'Biên nhận'

    },
    payments: {
        links: [],
        currentName: 'Lịch sử giao dịch'

    },
    dashboard: {
        links: [],
        currentName: 'Biểu đồ phí'

    },
    building: {
        links: [],
        currentName: <IntlMessages id={'building'}/>

    },
    apartments: {
        links: [],
        currentName: <IntlMessages id={'apartment'}/>

    },
    apartment_detail: {
        links: [{
            href: './',
            name: <IntlMessages id={'apartment'}/>
        }],
        currentName: <IntlMessages id={'apartment_detail'}/>

    },
    residents: {
        links: [],
        currentName: 'Cư dân'

    },
    resident_detail: {
        links: [{
            href: './',
            name: 'Cư dân'
        }],
        currentName: 'Chi tiết cư dân'

    },
    tickets: {
        links: [],
        currentName: 'Yêu cầu'

    },
    ticket_detail: {
        links: [{
            href: './',
            name: 'Yêu cầu'
        }],
        currentName: 'Chi tiết yêu cầu'

    },
    facilities: {
        links: [],
        currentName: 'Tiện ích'

    },
    facility_detail: {
        links: [{
            href: './',
            name: 'Tiện ích'
        }],
        currentName: 'Chi tiết tiện ích'

    },
    facility_booking: {
        links: [],
        currentName: 'Đăng kí tiện ích'

    },
    announcements: {
        links: [],
        currentName: 'Thông báo'

    },
    documents: {
        links: [],
        currentName: 'Sổ tay'

    },
    maintenances: {
        links: [],
        currentName: 'Bảo trì'

    },
    maintenance_tasks: {
        links: [],
        currentName: 'Công việc bảo trì'

    },
    parking: {
        links: [],
        currentName: 'Bãi đỗ xe'

    },
    parking_vehicle: {
        links: [],
        currentName: 'Thẻ xe'

    },
    parking_vehicle_booking: {
        links: [],
        currentName: 'Đăng kí thẻ xe'

    },
    parking_booking: {
        links: [],
        currentName: 'Đăng kí gửi xe'

    },
    parking_vehicle_detail: {
        links: [{
            href: './',
            name: 'Thẻ xe'
        }],
        currentName: 'Chi tiết thẻ xe'

    },
    poll_detail: {
        links: [{
            href: './',
            name: 'Khảo sát'
        }],
        currentName: 'Chi tiết khảo sát'

    },
    poll: {
        links: [],
        currentName: 'Khảo sát'

    },
    thread: {
        links: [],
        currentName: 'Thảo luận'

    },
    thread_detail: {
        links: [{
            href: './',
            name: 'Thảo luận'
        }],
        currentName: 'Chi tiết thảo luận'

    },
    user_blacklist: {
        links: [],
        currentName: 'Danh sách người dùng bị khóa thảo luận'

    },
    apartment_acc: {
        links: [],
        currentName: <IntlMessages id={'apartment_acc'}/>

    },

}



export default BreadcrumbsSchemma
