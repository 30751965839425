import React from "react";
import Widget from "components/Widget/index";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import PropTypes from "prop-types";

const BuildingInfo = ({data}) => {
    return (
        <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile">
            <div className="card-header">
                <h4 className="card-title mb-0">Thông tin dự án</h4>
            </div>

            <div className="jr-tabs-classic">
                <div className="jr-tabs-content ">
                    <div className="table-responsive-material">
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell><h4>Vị trí</h4></TableCell>
                                    <TableCell align="left" className='text-black-50'><h5>{data.full_address}</h5></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><h4>Đường dây nóng</h4></TableCell>
                                    <TableCell align="left" className='text-black-50'><h5>{data.hot_line}</h5></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><h4>Diện tích</h4></TableCell>
                                    <TableCell align="left" className='text-black-50'><h5>{data.area}</h5></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><h4>Mô tả</h4></TableCell>
                                    <TableCell align="left" className='text-black-50'><h5>{data.description}</h5></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </Widget>
    );
}
BuildingInfo.propTypes = {
    data: PropTypes.object.isRequired
}

export default BuildingInfo;
