import React from "react";
import {Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const MaintenanceApp = ({match}) => (
    <div className="app-wrapper">
        <Switch>
            <Route path={`${match.url}/parking`} component={asyncComponent(() => import('./Parking'))}/>
            <Route path={`${match.url}/parking-vehicle/:parkingVehicleId`} component={asyncComponent(() => import('./ParkingVehicleDetail'))}/>
            <Route path={`${match.url}/parking-vehicle`} component={asyncComponent(() => import('./ParkingVehicle'))}/>
            <Route path={`${match.url}/parking-vehicle-booking`} component={asyncComponent(() => import('./ParkingVehicleBooking'))}/>
            <Route component={asyncComponent(() => import("app/extraPages/routes/404"))}/>
        </Switch>
    </div>
);

export default MaintenanceApp;
