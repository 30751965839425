import React from "react";
import {Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const FacilitiesApp = ({match}) => (
    <div className="app-wrapper">
        <Switch>
            <Route path={`${match.url}/:facility_id`} component={asyncComponent(() => import('./FacilityDetail'))}/>
            <Route path={`${match.url}`} component={asyncComponent(() => import('./Facilities'))}/>
            <Route component={asyncComponent(() => import("app/extraPages/routes/404"))}/>
        </Switch>
    </div>
);

export default FacilitiesApp;
