import {ROUTE_NAME} from "../../../util/services/routes";
import BlockForm from "../../../components/Forms/BlockForm";
import {checkBlockCreatePermission} from "../../../util/function/checkGroup";


export const BlockMenuSchema = {
    update: {
        type: 'update',
        name: 'Cập nhật',
        title: 'Cập nhật block',
        form: ()=>BlockForm,
        checkGroup:checkBlockCreatePermission,
        conditionDisplay: (obj)=>true,
        urlParams: (obj)=> { return {id:obj.id}},
        routeName: ROUTE_NAME.BLOCK.UPDATE_BLOCK
    },
}
