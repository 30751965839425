import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IntlMessages from "util/IntlMessages";
import ImageUploader from 'react-images-upload';
import CircularProgress from "@material-ui/core/CircularProgress";
import apiService from "../../util/services/generalApi";
import {ROUTE_NAME} from "../../util/services/routes";
import {showMessage} from "../../redux/actions";
import {useDispatch} from "react-redux";


const UploadImageServiceDialog = ({open, handleClose, title, saving=false, single=false, maxWidth='sm', setSaving, service='property'}) => {
    const [pictures, setPictures] = React.useState([]);
    const dispatch = useDispatch()

    const onDrop = (pictureFiles, pictureDataURLs) => {
        setPictures(pictureFiles);
    }

    const fetchUploadFile = async (image) => {
        setSaving(true)
        const response = await apiService.create(
            {data:{file:image, service:'property', normal_size:900},url_params:{},routeName:ROUTE_NAME.STORAGE_SERVICE.UPLOAD_IMAGE}
        )
        if (response.message) {
            dispatch(showMessage(response.message))
        }else {
            handleClose(true, response.data)
        }
    }

    const submit = ()=>{
        if (pictures){
            fetchUploadFile(pictures[0])
        }
    }


    return (
        <Dialog
            fullWidth
            open={open}
            // onClose={handleClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <ImageUploader
                    withIcon={true}
                    buttonText='Chọn hình'
                    onChange={onDrop}
                    imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                    maxFileSize={5242880}
                    withPreview={true}
                    singleImage={single}
                />
            </DialogContent>
            <DialogActions>
                <div className='position-relative'>

                    <Button onClick={submit} color="primary" disabled={saving}>
                        <IntlMessages id='ok'/>
                    </Button>
                    {saving && <CircularProgress size={24} className='position-absolute text-primary'/>}
                </div>
                <Button onClick={() => handleClose(false, [])} color="warning" autoFocus disabled={saving}>
                    <IntlMessages id='cancel'/>
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default UploadImageServiceDialog
