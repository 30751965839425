import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import {errorDJRestToString} from "../../util/function/errorsProcess";
import apiService from "../../util/services/generalApi";
import {ROUTE_NAME} from "../../util/services/routes";
import {showMessage} from "../../redux/actions";
import AutocompleteBaseField from "./AutocompleteBaseField";


const ProvinceField = (props) => {
    const fieldName = 'province_id'
    const {setValue, register, setProvinceId, errors = {}, initInput = '', initValue} = props
    const dispatch = useDispatch();
    const [userInput, setUserInput] = React.useState(initInput)
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);


    const fetchData = async () => {
        setLoading(true)
        const response = await apiService.getList({
            filters: {limit: 100, offset: 0}, routeName: ROUTE_NAME.ADDRESS.PROVINCES
        });
        if (response.message) {
            dispatch(showMessage(response.message))
        } else {
            setData(response.data.results);
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        setValue(fieldName, initValue);
    }, [initValue])

    const handleChange = (value) => {
        setValue(fieldName, value ? value.id : null);
        setProvinceId(value ? value.id : null);
    }

    const handleInputChange = (event, value, reason) => {
        setUserInput(value);
    }

    return (
        <AutocompleteBaseField key={data} fieldName={fieldName} options={data ? data : []} register={register}
                                       onInputChange={handleInputChange} handleChange={handleChange}
                                       error={errorDJRestToString(errors[fieldName])} initValue={initValue}
                                       initInput={userInput} loading={loading}/>

)
}


ProvinceField.propTypes = {
    setValue: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    setProvinceId: PropTypes.func.isRequired,
    errors: PropTypes.object,
    initInput: PropTypes.string,
    initValue: PropTypes.string,
};

export default ProvinceField
