import React, {useState} from 'react';
import {useSelector} from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import ItemCardMenu from "./ItemCardMenu";

const ItemsRow = ({ data, displaySchema, menuSchema, setCurrentData, pushAction }) => {
    const [anchorE1, setAnchorE1] = useState(null);
    const [menuState, setMenuState] = useState(false);
    const {groups} = useSelector(({auth}) => auth);

    let menuList = [];
    Object.keys(menuSchema).forEach((item) => {
        if (menuSchema[item].checkGroup(groups) && menuSchema[item].conditionDisplay(data)) {
            menuList.push({ key: item, name: menuSchema[item].name })
        }
    })

    const onOptionMenuSelect = event => {
        setAnchorE1(event.currentTarget);
        setMenuState(true);
    };

    const handleRequestClose = (key) => {
        setMenuState(false);
        setCurrentData(data);
        pushAction(key, data)

    };

    return (

        <tr tabIndex={-1} key={data.id}>
            {displaySchema.map(obj => <td className='text-center'>{obj.display(data)}</td>)}

            <td className="text-right">
                {(menuList.length > 0) && <IconButton onClick={onOptionMenuSelect}>
                    <i className="zmdi zmdi-more-vert"/></IconButton>}
                <ItemCardMenu menuList={menuList} anchorEl={anchorE1} handleRequestClose={handleRequestClose} id={data.id} menuState={menuState} />
            </td>
        </tr>

    );
};

export default ItemsRow;
