import {
    GET_NOTIFICATIONS_SUCCESS,
    HIDE_MESSAGE,
    SHOW_MESSAGE,
} from "constants/ActionTypes";

const INIT_STATE = {
    alertMessage: '',
    showMessage: false,
    comment_count: 0,
    ticket_count: 0,
    facility_booking_count: 0,
    parking_booking_count: 0,
    comments: [],
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SHOW_MESSAGE: {
            return {
                ...state,
                alertMessage: action.payload,
                showMessage: true,
                loader: false
            }
        }
        case HIDE_MESSAGE: {
            return {
                ...state,
                alertMessage: '',
                showMessage: false,
                loader: false
            }
        }
        case GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}
