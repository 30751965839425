import React from "react";
import PropTypes from "prop-types";
import {FormHelperText, MenuItem, Select} from "@material-ui/core";
import {errorDJRestToString} from "../../util/function/errorsProcess";

const SelectField = (props) => {
    const {fieldName, register, selectDict, onChange, errors = {}, defaultValue, disabled = false, allSelect=false} = props
    const extendProps = Object.assign({}, onChange ? {onChange} : null)
    let strDefaultValue = defaultValue === false ? String(defaultValue) : defaultValue
    return (
        <>
            <Select
                {...extendProps}
                defaultValue={strDefaultValue ? strDefaultValue : Object.keys(selectDict)[0]}
                fullWidth
                disabled={disabled}
                label={''}
                size='small'
                name={fieldName}
                inputRef={(ref) => {
                    if (!ref) return;
                    register({name: fieldName, value: ref.value})
                }}
                error={errors[fieldName]}
            >
                {allSelect&&<MenuItem value={'null'}>Tất cả</MenuItem>}
                {Object.keys(selectDict).map(key => {
                    return <MenuItem value={key}>{selectDict[key]}</MenuItem>
                })}

            </Select>
            <FormHelperText error>{errorDJRestToString(errors[fieldName])}</FormHelperText>
        </>
    )
}

SelectField.propTypes = {
    fieldName: PropTypes.string.isRequired,
    register: PropTypes.func.isRequired,
    selectDict: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    errors: PropTypes.object,
    defaultValue: PropTypes.any,
    allSelect: PropTypes.bool,
    disabled: PropTypes.bool,
};
export default SelectField
