import React from 'react';
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button';
import {keyboardDateTime12h} from "../../util/function/datetimeFunc";
import {NavLink} from "react-router-dom";


const NotificationItem = ({notification}) => {
  const {created_by, description, ticket_code, created, building_id, ticket_id} = notification;
  return (
    <li className="media">
        <Avatar
            alt={created_by?.fullname}
            src={created_by?.image_thumb}
            className=" mr-2"
        />
      <NavLink to={`/app/${building_id}/ticket/${ticket_id}`}>

          <div className="media-body align-self-center">
              <p className="sub-heading mb-0">{description.slice(0,50)}..</p>
              <span>{ticket_code}</span><br/>
              <span className='sub-heading float-right mt-auto'>{keyboardDateTime12h(created)}</span>
          </div>
      </NavLink>
    </li>
  );
};

export default NotificationItem;
