import React from "react";
import IntlMessages from "../../../util/IntlMessages";

export const blockDisplaySchema = [
    {
        title: <IntlMessages id={'block_name'}/>,
        fieldName: 'name',
        display: function (obj) {
            return obj[this.fieldName]
        }
    },
    // {
    //     title: 'Mô tả',
    //     fieldName: 'description',
    //     display: function (obj) {
    //         return obj[this.fieldName]
    //     }
    // },

];


