import {TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, {useEffect} from "react";
import PropTypes from "prop-types";


const AutocompleteBaseField = (props) => {
    const {fieldName, options, register, handleChange, onInputChange, loading=false, error, initInput, initValue, disabled=false, multiple=false, get_title=(obj)=>obj['name'], renderOption,  extendParams} = props
    const extendProps = Object.assign({}, onInputChange?{onInputChange}:null)
    const defaultValue =  multiple?options.filter(option => {
        for (var i=0; i < initValue.length; i++) {
            if (initValue[i] === option.id) {
                return true
            }
        }
        return false
    }):options.filter(option => {
        return initValue === option.id;

    })[0]


    useEffect(() => {
        register({name: fieldName});
    }, []);

    return (
        <Autocomplete
            defaultValue={defaultValue}
            disabled={disabled}
            multiple={multiple}
            // inputValue={initInput}
            // value={initInput}
            // getOptionSelected={(option, value) => option.id === initValue}
            name={fieldName}
            options={options}
            loading={loading}
            loadingText='Đang tải'
            autoHighlight
            {...extendProps}
            {...extendParams}
            onChange={(e, value) => handleChange(value)}
            getOptionLabel={option =>
                typeof option === "string" ? option : get_title(option)
            }
            renderOption={(option, state) => typeof(renderOption) !== 'function' ?get_title(option):renderOption(option, state)}
            renderInput={params => (
                <TextField
                    disabled={options.length === 0}
                    {...params}
                    fullWidth
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "off"
                    }}
                    helperText={error}
                    error={!!error}
                />
            )}
        />
    )
}


AutocompleteBaseField.propTypes = {
    fieldName: PropTypes.string.isRequired,
    initInput: PropTypes.string,
    initValue: PropTypes.any,
    options: PropTypes.array.isRequired,
    register: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    onInputChange: PropTypes.func,
    loading: PropTypes.bool,
    error: PropTypes.string,
    multiple: PropTypes.bool,
    disabled: PropTypes.bool,
    get_title: PropTypes.func,
    renderOption: PropTypes.func,
    extendParams: PropTypes.object,
};


export default AutocompleteBaseField
