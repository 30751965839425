import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import generalApi from "../../util/services/generalApi";
import {GET_NOTIFICATIONS} from "constants/ActionTypes";

import {getNotificationsSuccess, showMessage} from "redux/actions/Common"
import {ROUTE_NAME} from "../../util/services/routes";
import {PERMISSION_DICT} from "../../constants/statusDict/user";

const getPermissions = state => state.auth?.groups || [];
const getBuildingId = state => state.building?.buildingId;


function* GetNotifications() {
    try {
        const permissions = yield select(getPermissions);
        const building_id = yield select(getBuildingId);
        let response_ticket, response_facility, response_parking;
        if (building_id){
            if(permissions.includes(PERMISSION_DICT.TICKET_VIEW)){
                response_ticket = yield call(generalApi.getList, {filters:{building_id}, routeName:ROUTE_NAME.TICKET.GET_TICKET_COMMENTS_NOTIFY});
            }
            if(permissions.includes(PERMISSION_DICT.FACILITY_VIEW)){
                response_facility = yield call(generalApi.getList, {filters:{building_id}, routeName:ROUTE_NAME.FACILITY.GET_FACILITY_NOTIFY});
            }
            if(permissions.includes(PERMISSION_DICT.PARKING_VIEW)){
                response_parking = yield call(generalApi.getList, {filters:{building_id}, routeName:ROUTE_NAME.PARKING.GET_PARKING_NOTIFY});
            }
            if (response_ticket?.message || response_facility?.message|| response_parking?.message) {
                // yield put(showMessage(response_ticket.message));
                // yield put(showMessage(response_facility.message));
            }
        }

        yield put(getNotificationsSuccess({...response_ticket?.data, ...response_facility?.data, ...response_parking?.data}));
    } catch (error) {
        yield put(showMessage(error));
    }
}

// ------------------------------------------------------------------

export function* watchNotification() {
    yield takeEvery(GET_NOTIFICATIONS, GetNotifications);
}

//-------------------------------------------
export default function* rootSaga() {
    yield all([
        fork(watchNotification),
    ]);
}
