import React, {useEffect} from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import PropTypes from "prop-types";
import {FEE_TYPE_DISPLAY_DICT} from "../../constants/statusDict/fee";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" color='primary'/>;
const checkedIcon = <CheckBoxIcon fontSize="small" color='primary'/>;


const FeeDisplayField = (props) => {
    const fieldName='fee_display'
    const {setValue, register, error, defaultValue=[]} = props

    useEffect(() => {
        register({name: fieldName});
        setValue(fieldName, defaultValue);
    }, []);

    const handleChange = (value) => {
        setValue(fieldName, value.map(obj=>obj.id));
    }

    return (
        <Autocomplete
            multiple
            id="tags-standard"
            options={FeeDisplay}
            getOptionLabel={(option) => option.name}
            defaultValue={FeeDisplay.filter(obj=>(defaultValue||[]).includes(obj.id))}
            disableCloseOnSelect
            // groupBy={(option) => PERMISSION_GROUP_DISPLAY_DICT[option.id]}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    // label="Multiple values"
                    placeholder="Phí hiển thị"
                    helperText={error}
                    error={!!error}
                />
            )}
            renderOption={(option, { selected }) => (
                <React.Fragment>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.name}
                </React.Fragment>
            )}
            onChange={(e, value) => handleChange(value)}
        />
    );
}

const FeeDisplay = Object.keys(FEE_TYPE_DISPLAY_DICT).map(key=>{ return {id:key, name:FEE_TYPE_DISPLAY_DICT[key]}})

FeeDisplayField.propTypes = {
    defaultValue: PropTypes.string,
    error: PropTypes.string.isRequired,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired
};

export default FeeDisplayField
