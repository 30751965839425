import {
    FETCH_BLOCKS,
    FETCH_BLOCKS_SUCCESS
} from 'constants/ActionTypes';



export const fetchBlocks = (payload) => {
    return {
        type: FETCH_BLOCKS,
        payload: payload,
    }
};

export const fetchBlocksSuccess = (payload) => {
    return {
        type: FETCH_BLOCKS_SUCCESS,
        payload: payload
    }
};
