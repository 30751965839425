import React from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import Navigation from "../../components/Navigation";
import {useSelector} from "react-redux";
import {
    checkAdminPermission,
    checkAnnouncementViewPermission,
    checkDashBoardViewPermission,
    checkDocumentViewPermission,
    checkFacilityViewPermission,
    checkFeeViewPermission,
    checkMaintenanceViewPermission,
    checkParkingViewPermission,
    checkReceiptViewPermission,
    checkResidentViewLimitPermission, checkThreadViewPermission,
    checkTicketViewPermission,
    checkViewBasicPermission,
    checkViewSectionOther
} from "../../util/function/checkGroup";

const SideBarContent = () => {
    const {buildingId} = useSelector(({building}) => building);

    const navigationMenus = [
        {
            name: 'building',
            checkGroup: checkViewBasicPermission,
            type: 'section',
            children: [


                {
                    name: 'info',
                    checkGroup: checkViewBasicPermission,
                    type: 'item',
                    icon: 'city-alt',
                    link: `/app/${buildingId}/building`
                },
                {
                    name: 'apartment',
                    checkGroup: checkViewBasicPermission,
                    type: 'item',
                    icon: 'home',
                    link: `/app/${buildingId}/apartment`
                },
                {
                    name: 'document',
                    checkGroup: checkDocumentViewPermission,
                    type: 'item',
                    icon: 'floppy',
                    link: `/app/${buildingId}/document`
                },
                {
                    name: 'resident',
                    checkGroup: checkResidentViewLimitPermission,
                    type: 'item',
                    icon: 'male-female',
                    link: `/app/${buildingId}/resident`
                },
                {
                    name: 'account',
                    checkGroup: checkAdminPermission,
                    type: 'item',
                    icon: 'accounts',
                    link: `/app/${buildingId}/user`
                },
            ]
        },
        {
            name: 'other',
            checkGroup: checkViewSectionOther,
            type: 'section',
            children: [
                {
                    name: 'fee_management',
                    checkGroup: checkReceiptViewPermission,
                    type: 'collapse',
                    icon: 'money-box',
                    children: [

                        {
                            name: 'bill',
                            checkGroup: checkReceiptViewPermission,
                            type: 'item',
                            icon: 'receipt',
                            link: `/app/${buildingId}/fee`
                        },
                        {
                            name: 'receipts',
                            checkGroup: checkReceiptViewPermission,
                            type: 'item',
                            icon: 'money-box',
                            link: `/app/${buildingId}/receipt`
                        },
                        {
                            name: 'fee_imports',
                            checkGroup: checkFeeViewPermission,
                            type: 'item',
                            icon: 'upload',
                            link: `/app/${buildingId}/fee-import`
                        },
                        {
                            name: 'receipt_imports',
                            checkGroup: checkFeeViewPermission,
                            type: 'item',
                            icon: 'upload',
                            link: `/app/${buildingId}/receipt-import`
                        },
                        {
                            name: 'apartment_acc',
                            checkGroup: checkFeeViewPermission,
                            type: 'item',
                            icon: 'home',
                            link: `/app/${buildingId}/apartment-acc`
                        },
                    ]
                },
                {
                    name: 'facility',
                    checkGroup: checkFacilityViewPermission,
                    type: 'collapse',
                    icon: 'run',
                    notify_count: 'facility_booking_count',
                    children: [
                        {
                            name: 'facility',
                            checkGroup: checkFacilityViewPermission,
                            type: 'item',
                            icon: 'run',
                            link: `/app/${buildingId}/facility`
                        },
                        {
                            name: 'facility_booking',
                            checkGroup: checkFacilityViewPermission,
                            type: 'item',
                            icon: 'check-square',
                            link: `/app/${buildingId}/facility-booking`,
                            notify_count: 'facility_booking_count'
                        },

                    ]
                },

                {
                    name: 'maintenance',
                    checkGroup: checkMaintenanceViewPermission,
                    type: 'collapse',
                    icon: 'calendar-check',
                    children: [
                        {
                            name: 'maintenance',
                            checkGroup: checkMaintenanceViewPermission,
                            type: 'item',
                            icon: 'calendar-check',
                            link: `/app/${buildingId}/maintenance/maintenance`
                        },
                        {
                            name: 'maintenance_task',
                            checkGroup: checkMaintenanceViewPermission,
                            type: 'item',
                            icon: 'alarm-check',
                            link: `/app/${buildingId}/maintenance/task`
                        },

                    ]
                },
                {
                    name: 'parking',
                    checkGroup: checkParkingViewPermission,
                    type: 'collapse',
                    icon: 'car',
                    notify_count: 'parking_booking_count',
                    children: [
                        {
                            name: 'parking',
                            checkGroup: checkParkingViewPermission,
                            type: 'item',
                            icon: 'car',
                            link: `/app/${buildingId}/parking/parking`
                        },
                        {
                            name: 'parking-vehicle',
                            checkGroup: checkParkingViewPermission,
                            type: 'item',
                            icon: 'card',
                            link: `/app/${buildingId}/parking/parking-vehicle`
                        },
                        {
                            name: 'parking-vehicle-booking',
                            checkGroup: checkParkingViewPermission,
                            type: 'item',
                            icon: 'developer-board',
                            link: `/app/${buildingId}/parking/parking-vehicle-booking`,
                            notify_count: 'parking_booking_count',
                        },

                    ]
                },
                {
                    name: 'ticket',
                    checkGroup: checkTicketViewPermission,
                    type: 'item',
                    icon: 'comments',
                    link: `/app/${buildingId}/ticket`,
                    notify_count: 'ticket_count'
                },
                {
                    name: 'announcement',
                    checkGroup: checkAnnouncementViewPermission,
                    type: 'item',
                    icon: 'volume-up',
                    link: `/app/${buildingId}/announcement`
                },
                {
                    name: 'forum',
                    checkGroup: checkReceiptViewPermission,
                    type: 'collapse',
                    icon: 'comment-more',
                    children: [
                        {
                            name: 'poll',
                            checkGroup: checkThreadViewPermission,
                            type: 'item',
                            icon: 'view-list',
                            link: `/app/${buildingId}/thread/poll`,
                        },
                        {
                            name: 'thread',
                            checkGroup: checkThreadViewPermission,
                            type: 'item',
                            icon: 'comment-more',
                            link: `/app/${buildingId}/thread/thread`,
                        },
                        {
                            name: 'user_blacklist',
                            checkGroup: checkThreadViewPermission,
                            type: 'item',
                            icon: 'block-alt',
                            link: `/app/${buildingId}/thread/black-list`,
                        },
                    ]
                }


            ]
        },
        {
            name: 'dashboard',
            checkGroup: checkDashBoardViewPermission,
            type: 'section',
            children: [

                {
                    name: 'general_dashboard',
                    checkGroup: checkDashBoardViewPermission,
                    type: 'item',
                    icon: 'chart-donut',
                    link: `/app/${buildingId}/dashboard/general`
                },
                {
                    name: 'ticket_dashboard',
                    checkGroup: checkDashBoardViewPermission,
                    type: 'item',
                    icon: 'chart',
                    link: `/app/${buildingId}/dashboard/ticket`
                },
            ]
        },


    ];

    return (
        <CustomScrollbars className=" scrollbar">
            <Navigation menuItems={navigationMenus}/>
        </CustomScrollbars>
    );
};

export default SideBarContent;
