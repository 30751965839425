import {
    FETCH_BUILDING_SUCCESS,
    FETCH_BUILDINGS_SUCCESS, FETCH_COMPANY_SUCCESS,
    SET_BUILDING_ID_DEFAULT_SUCCESS,
    SET_BUILDING_TYPE,
} from "../../constants/ActionTypes";

const HIDDEN_NOZOMI_BUILDINGS = [
    {
      id: 'f907daae-00c3-4e7b-9f7d-65d66ae6bc83',
      name: 'Moonlight Residences',
    },
    {
      id: '5291a323-26a9-42f6-8c77-21514296bcb8',
      name: 'Central Premium',
    },
  ]


const INIT_STATE = {
    loading: true,
    buildings: [],
    buildingId: null,
    type: 0,
    building: {},
    company: {}
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_BUILDINGS_SUCCESS: {
            // if (!state.buildingId){
            //     state.buildingId = action.payload[0].id
            // }
            return {
                ...state,
                buildings: action.payload.filter(
                    (buildingItem) => !HIDDEN_NOZOMI_BUILDINGS.some((item) => item.id === buildingItem.id)
                  ),
                loading: false,
            }
        }
        case SET_BUILDING_ID_DEFAULT_SUCCESS: {
            return {
                ...state,
                buildingId: action.payload,
            }
        }
        case SET_BUILDING_TYPE: {
            return {
                ...state,
                type: action.payload,
            }
        }
        case FETCH_BUILDING_SUCCESS: {
            return {
                ...state,
                building: action.payload,
            }
        }
        case FETCH_COMPANY_SUCCESS: {
            return {
                ...state,
                company: action.payload,
            }
        }
        default:
            return state;
    }
}
