import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {FETCH_BLOCKS,} from "constants/ActionTypes";
import {showMessage} from "../actions/Common"
import axiosInstance from "../../util/axiosApi";
import {ROUTE_NAME, routeTo} from "../../util/services/routes";
import {fetchBlocksSuccess} from "../actions/Blocks";


const getBlockRequest = async (action) =>
    await axiosInstance.get(routeTo(ROUTE_NAME.BLOCK.GET_BLOCKS, action.payload)).then(response => {
        return response
    }).catch(error => error)

function * getBlocks(action) {
    try {
        const blocks = yield call(getBlockRequest, action);
        if (blocks.message) {
            yield put(showMessage(blocks.message));
        } else {
            yield put(fetchBlocksSuccess(blocks.data));

        }
    } catch (error) {
        yield put(showMessage(error));
    }
}

export function* getListBlock() {
    yield takeEvery(FETCH_BLOCKS, getBlocks);
}


export default function* rootSaga() {
    yield all([
        fork(getListBlock)
    ]);
}
