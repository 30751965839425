import {ROUTE_NAME} from "../../../util/services/routes";
import BlockForm from "../../../components/Forms/BlockForm";
import {checkBlockCreatePermission} from "../../../util/function/checkGroup";

const createBlockSchema = {
    createButtonTitle: 'Tạo block',
    createForm: ()=>BlockForm,
    routeCreateName: ROUTE_NAME.BLOCK.CREATE_BLOCK,
    checkCreateGroup: checkBlockCreatePermission
}
export default createBlockSchema
