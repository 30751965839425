import React from "react";
import IntlMessages from "../../../util/IntlMessages";

export const apartmentTypeDisplaySchema = [
    {
        title: <IntlMessages id={'apartment_type'}/>,
        fieldName: 'name',
        display: function (obj) {
            return obj[this.fieldName]
        }
    },
    {
        title: 'Phòng ngủ',
        fieldName: 'bedrooms',
        display: function (obj) {
            return obj[this.fieldName]
        }
    },
    {
        title: 'Phòng tắm',
        fieldName: 'bathrooms',
        display: function (obj) {
            return obj[this.fieldName]
        }
    },
    {
        title: 'Bếp',
        fieldName: 'kitchens',
        display: function (obj) {
            return obj[this.fieldName]
        }
    },
    {
        title: 'Ban công',
        fieldName: 'balconies',
        display: function (obj) {
            return obj[this.fieldName]
        }
    },

];


