import ViMessages1 from '../locales/vi_VN.json';
import ViMessages2 from '../locales/vi_VN_Nha_pho.json';

const viLangNhaPho = {
  messages: {
    ...ViMessages1,
    ...ViMessages2
  },
  locale: 'vi-VN',
};
export default viLangNhaPho;
