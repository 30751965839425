import React from "react";
import {Grid, TextField, Typography} from '@material-ui/core'
import {errorDJRestToString} from "util/function/errorsProcess";
import ProvinceField from "components/CustomField/ProvinceField";
import DistrictField from "components/CustomField/DistrictField";
import WardField from "components/CustomField/WardField";
import CustomNumberField from "../../CustomField/CustomNumberField";
import FeeDisplayField from "../../CustomField/FeeDisplayField";
import SelectField from "../../CustomField/SelectField";
import {BUILDING_STATUS_SALE_DISPLAYS_DICT, BUILDING_TYPE_DISPLAYS_DICT} from "../../../constants/statusDict/building";
import {FEE_TYPE_DISPLAY_DICT} from "../../../constants/statusDict/fee";


const BuildingForm = ({register, setValue, initState = {}, errors = {}}) => {
    const [provinceId, setProvinceId] = React.useState(initState['province_id'])
    const [districtId, setDistrictId] = React.useState(initState['district_id'])
    const [wardId, setWardId] = React.useState(initState['ward_id'])
    const [districtName, setDistrictName] = React.useState(initState['district_name'])
    const [wardName, setWardName] = React.useState(initState['ward_name'])


    const handleChangeProvinceID=(value)=>{
        setProvinceId(value)
        setDistrictId(null);
        setDistrictName(null);
        setWardId(null);
        setWardName(null);
    }

    const handleChangeDistrictID=(value)=>{
        setDistrictId(value)
        setWardId(null);
        setWardName(null);
    }
    return (
        <Grid container spacing={4}>

            <Grid item xs={12} sm={6} md={4}>
                <Typography variant="caption" gutterBottom={true}>Tên dự án</Typography>
                <TextField inputRef={register} name="name" fullWidth error={!!errors.name}
                           helperText={errorDJRestToString(errors.name)} defaultValue={initState.name}/>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Typography variant="caption" gutterBottom={true}>Diện tích</Typography>
                <CustomNumberField fieldName='area' register={register} setValue={setValue} decimalPlaces={2} required={true} defaultValue={initState['area']} errors={errors}/>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Typography variant="caption" gutterBottom={true}>Hotline</Typography>
                <TextField inputRef={register} name="hot_line" fullWidth error={!!errors.hot_line}
                           helperText={errorDJRestToString(errors.hot_line)} defaultValue={initState.hot_line}/>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Typography variant="caption" gutterBottom={true}>Tỉnh - Thành phố</Typography>
                <ProvinceField setValue={setValue} register={register} setProvinceId={handleChangeProvinceID}  errors={errors} initValue={provinceId} initInput={initState['province_name']}/>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Typography variant="caption" gutterBottom={true}>Quận - Huyện</Typography>
                <DistrictField key={provinceId} setValue={setValue} register={register} provinceId={provinceId}
                               setDistrictId={handleChangeDistrictID} errors={errors} initValue={districtId} initInput={districtName}/>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Typography variant="caption" gutterBottom={true}>Phường - Xã</Typography>
                <WardField key={districtId} setValue={setValue} register={register} districtId={districtId} errors={errors} initValue={wardId} initInput={wardName}/>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Typography variant="caption" gutterBottom={true}>Địa chỉ</Typography>
                <TextField inputRef={register} name="address" fullWidth error={!!errors.address}
                           helperText={errorDJRestToString(errors.address)} defaultValue={initState.address}/>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Typography variant="caption" gutterBottom={true}>Trạng thái bán</Typography>
                <SelectField fieldName={'status_sale'} register={register} selectDict={BUILDING_STATUS_SALE_DISPLAYS_DICT} defaultValue={initState['status_sale']}/>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Typography variant="caption" gutterBottom={true}>Loại</Typography>
                <SelectField fieldName={'type'} register={register} selectDict={BUILDING_TYPE_DISPLAYS_DICT} defaultValue={initState['type']}/>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="caption" gutterBottom={true}>Phí hiển thị</Typography>
                <FeeDisplayField register={register} setValue={setValue} error={errorDJRestToString(errors.fee_display)} defaultValue={initState['fee_display']}/>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="caption" gutterBottom={true}>Mô tả</Typography>
                <TextField inputRef={register} name="description" fullWidth error={!!errors.description}
                           helperText={errorDJRestToString(errors.description)} defaultValue={initState.description}
                           multiline variant='outlined'/>
            </Grid>

        </Grid>
    )
}

export default BuildingForm

