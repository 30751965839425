import React from "react";
import {Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";
import {withRouter} from "react-router";
import UserApp from "./userApp";
import FeeApp from "./feeApp";
import ReceiptApp from "./receiptApp";
import PaymentApp from "./paymentApp";
import DashboardApp from "./DashBoardApp";
import BuildingApp from "./buildingApp";
import ApartmentApp from "./apartmentApp";
import ResidentApp from "./residentApp";
import TicketApp from "./ticketApp";
import FeeImportApp from "./feeImportApp";
import FacilityApp from "./facilityApp";
import FacilityBookingApp from "./facilityBookingApp";
import AnnouncementApp from "./AnnouncementApp";
import DocumentApp from "./DocumentApp";
import ForumApp from "./ForumApp";
import MaintenanceApp from "./MaintenanceApp";
import ParkingApp from "./ParkingApp";
import ReceiptImportApp from "./receiptImportApp";
import ApartmentAccApp from "./apartmentAccApp";

const Routes = ({ match }) =>
  <Switch>
    <Route path={`${match.url}/building`} component={BuildingApp}/>
    <Route path={`${match.url}/apartment`} component={ApartmentApp}/>
    <Route path={`${match.url}/resident`} component={ResidentApp}/>
    <Route path={`${match.url}/ticket`} component={TicketApp}/>

    <Route path={`${match.url}/user`} component={UserApp}/>
    <Route path={`${match.url}/fee`} component={FeeApp}/>
    <Route path={`${match.url}/fee-import`} component={FeeImportApp}/>
    <Route path={`${match.url}/receipt-import`} component={ReceiptImportApp}/>
    <Route path={`${match.url}/receipt`} component={ReceiptApp}/>
    <Route path={`${match.url}/facility`} component={FacilityApp}/>
    <Route path={`${match.url}/facility-booking`} component={FacilityBookingApp}/>
    <Route path={`${match.url}/announcement`} component={AnnouncementApp}/>
    <Route path={`${match.url}/document`} component={DocumentApp}/>
    <Route path={`${match.url}/maintenance`} component={MaintenanceApp}/>
    <Route path={`${match.url}/parking`} component={ParkingApp}/>

      <Route path={`${match.url}/thread`} component={ForumApp}/>
      <Route path={`${match.url}/apartment-acc`} component={ApartmentAccApp}/>

      <Route path={`${match.url}/payment`} component={PaymentApp}/>
    <Route path={`${match.url}/dashboard`} component={DashboardApp}/>
    <Route path={`${match.url}/extra/403`} component={asyncComponent(() => import("app/extraPages/routes/403"))}/>
    <Route component={asyncComponent(() => import("app/extraPages/routes/404"))}/>
  </Switch>;


export default withRouter(Routes);
